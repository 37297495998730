<template>
    <div class="container">
      <h2 class="text-center my-5">Delete Account</h2>
      <p class="description text-center fs-5 mb-5">After filling out the form below, we will remove all account information within 5 business days (excluding holidays).</p>
      <div class="row justify-content-center text-secondary mb-4">
        <div class="text-center mb-2 fs-18">
         <vue-feather type="alert-triangle" class="text-danger me-2"></vue-feather>
          <span>Please note:</span>
        </div>
        <ul class="col-11 col-lg-7">
          <li>Account deletion is permanent and irreversible. After successful deletion, you will not be able to recover the account, log in, or view previous account history.</li>
          <li>After the account is successfully deleted, Katcher will continue to retain transaction data for financial auditing purposes.</li>
          <li>In certain circumstances, Katcher reserves the right to reject account application requests.</li>
        </ul>
      </div>

      <div class="row">
        <Form ref="form" class="delete-form p-4 rounded col-11 col-lg-7 mx-auto" @submit="submitForm">
          <div class="row justify-content-between">
            <div class="col-12 col-lg-6">
              <label for="name" class="col-form-label mt-3">Name*</label>
              <Field
                 v-model="form.name"  name="name" class="form-control" placeholder="Name"
                rules="required" type="text" />
              <ErrorMessage name="name" class="text-danger" />
            </div>
            <div class="col-12 col-lg-6">
              <label for="address" class="col-form-label mt-3">Address*</label>
              <Field
                v-model="form.address" name="address" class="form-control" placeholder="Address"
                rules="required" type="text" />
              <ErrorMessage name="address" class="text-danger" />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-12 col-lg-6">
              <label for="email" class="col-form-label mt-3">E-mail*</label>
              <Field
                v-model="form.email" name="email" rules="required|email"
                type="text" class="form-control" placeholder="Email"/>
              <ErrorMessage name="email" class="text-danger" />
            </div>
            <div class="col-12 col-lg-6">
              <label for="phone" class="col-form-label mt-3">Phone*</label>
              <div class="d-flex gap-2">
                <Field name="code" v-slot="{ field }" :rules="{ required: true }" >
                   <multiselect v-model="codeVal" 
                   :options="codeOptions" 
                   :searchable="true" 
                   :close-on-select="true" 
                   :show-labels="false" 
                   placeholder="Code" 
                   v-bind="field" 
                   track-by="code" 
                   :custom-label="customLabel" 
                   :allow-empty="false"
                   @update:modelValue="val => selectChange(val)">
                   <template #singleLabel="props"><span class="option__desc"><span
                      class="option__title">{{ props.option.code }}</span></span>
                    </template>
                    <template #option="props">
                    <span class="me-3">{{ props.option.code }}</span>{{ props.option.name }}
                    </template>
                  </multiselect>

                  </Field>
                  <Field
                  v-model="form.phone" name="phone" class="form-control" placeholder="Phone"
                  rules="required|numeric" type="text" />
                </div>
                <ErrorMessage name="code" class="text-danger me-2"></ErrorMessage>
                <ErrorMessage name="phone" class="text-danger" />
              </div>
          </div>
          <div class="text-center">
            <button class="btn btn-primary mt-5">Request to Delete Account</button>
          </div>
        </Form>
      </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      form: {
        name: '',
        address: '',
        email: '',
        phone: '',
        code: ''
      },
      codeVal: '',
      codeOptions: [ {
            "code": "+93",
            "name": "Afghanistan"
        },
        {
            "code": "+358-18",
            "name": "Aland Islands"
        },
      ],
    }
  },
  mounted() {
    this.getCode();
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    ...mapState('user', ['accessToken']),
  },
  methods: {
    getCode() {
      const api = `${process.env.VUE_APP_PATH}/global/phone_codes`;
      this.axios.get(api, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'apiToken': process.env.VUE_APP_API_TOKEN
        }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.codeOptions = res.data.codes;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    customLabel ({code, name}) {
      return `${code} - ${name}`;
    },
    selectChange(val) {
      this.form.code = val.code;
    },
    submitForm() {
      const apiUrl = `${process.env.VUE_APP_PATH}/deleted_account/create`;
      
      this.axios.post(apiUrl, this.form, {
        headers: {
          'apiToken': process.env.VUE_APP_API_TOKEN
        }
      })
      .then(res => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$toastSuccess('Request sent successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
      })
      .catch(error => console.log(error));

      this.$refs.form.resetForm();
      this.codeVal = '';
      this.form.code = '';
    }
  }
};
</script>

<style lang="scss">
.delete-form {
  background-color: var(--bs-gray-300);
  & .multiselect__content-wrapper {
    width: 280px!important;
  }
  & ul>li {
    list-style-type: disc;
}
}


</style>
