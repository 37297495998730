export var menuItems={
  "data": [
    {
      "headTitle1": "General",
      "type": "headtitle"
    },
    {
      "title": "Dashboard",
      "path": "/",
      "icon": "stroke-home",
      "iconf":"fill-home",
      "type": "link",
      "badgeType": "light-primary",
      "active": false,
    },
    {
      "title": "User Management",
      "path": "/user-management/index",
      "icon": "stroke-widget",
      "iconf":"fill-widget",
      "type": "link",
      "active": false,
    },
    {
      "title": "Staff Management",
      "path": "/staff-management/index",
      "icon": "stroke-layout",
      "iconf":"fill-layout",
      "type": "link",
      "active": false,
    },
    {
      "title": "House List",
      "path": "/house-list/index",
      "icon": "stroke-layout",
      "iconf":"fill-layout",
      "type": "link",
      "active": false,
    },
    {
      "headTitle1": "System and setting",
      "type": "headtitle"
    },
    {
      "title": "Notifications", 
      "path": "/notifications/index",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active": false,
    },
    {
      "title": "Device Management", 
      "path": "/device-management",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active": false,
    },
    {
      "title": "Settings",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/setting/terms-of-service",
          "title": "Terms of Service",
          "type": "link",
          "active": false
        },
        {
          "path": "/setting/member-criteria",
          "title": "Member Criteria",
          "type": "link",
          "active": false
        },
        {
          "path": "/setting/contact-to-service",
          "title": "Contact to Service",
          "type": "link",
          "active": false
        },
         {
          "path": "/setting/information",
          "title": "Information",
          "type": "link",
          "active": false
        },
         {
          "path": "/setting/about-us",
          "title": "About us",
          "type": "link",
          "active": false
        },
         {
          "path": "/setting/faq",
          "title": "FAQ",
          "type": "link",
          "active": false
        },
        {
          "path": "/setting/update",
          "title": "Update",
          "type": "link",
          "active": false
        },
        {
          "path": "/setting/administrator",
          "title": "Administrator",
          "type": "link",
          "active": false
        },
        {
          "path": "/setting/role-setting",
          "title": "Role Setting",
          "type": "link",
          "active": false
        },
      ]
    },
    {
      "title": "Delete Account Request", 
      "path": "/delete-account-request",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active": false,
    },
    {
      "title": "Node Log List", 
      "path": "/node-log-list",
      "icon": "stroke-ecommerce",
      "iconf":"fill-ecommerce",
      "type": "link",
      "active": false,
    },
  ]
}