<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form ref="form" @submit.prevent="handleDelete">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <vue-feather type="alert-triangle" size="42" class="text-danger mb-3"></vue-feather>
            <h5>Are you sure you want to delete?</h5>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-light" @click.prevent="$emit('hide')">Cancel</button>
              <button type="submit" class="btn btn-danger">Confirm</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  props: ['type', 'id'],
  emits: ['update', 'hide', 'mqttDelete'],
  methods: {
    handleDelete() {
      switch(this.type) {
        case 'house':
        case 'notification':
        case 'termsOfService':
        case 'memberOfCriteria':
        case 'contactToService':
        case 'information':
        case 'aboutUs':
        case 'faq':
        case 'update':
        case 'administrator':
        case 'roleSetting':
          this.$emit('update');
          break;
        case 'sensor':
        case 'gateway':
          const api = `${process.env.VUE_APP_PATH}/house/device/${this.type}/remove`;
          const payload = {
            [this.type === 'gateway' ? 'gateway_id' : 'sensor_id']: this.id
          };
    
          this.axios.post(api, payload)
          .then((res) => {
            if (res.data.status_code === 'SYSTEM_1000') {
              if(this.type === 'gateway') {
                this.$emit('hide');
                this.$toastSuccess('Deleted successfully');
                this.$emit('update');
              } else if(this.type === 'sensor') {
                this.$emit('mqttDelete');
              }
            } else {
              const msg = checkStatusCode(res.data.status_code);
              this.$toastError(msg);
            }
          }).catch(error => {
            console.log('Error:', error);
          });
          break;
        default:
          this.$emit('update');
          break;
      }
    },
    // handleDelete() {
    //   if(this.type === 'notification') {
    //     this.$emit('update');
    //     return;
    //   }
    //   const api = `${process.env.VUE_APP_PATH}/house/device/${this.type}/remove`;
    //   const payload = {
    //     [this.type === 'gateway' ? 'gateway_id' : 'sensor_id']: this.id
    //   };

    //   this.axios.post(api, payload)
    //   .then((res) => {
    //     if (res.data.status_code === 'SYSTEM_1000') {
    //       if(this.type === 'gateway') {
    //         this.$emit('hide');
    //         this.$toastSuccess('Deleted successfully');
    //         this.$emit('update');
    //       } else if(this.type === 'sensor') {
    //         this.$emit('mqttDelete');
    //       }
    //     } else {
    //       const msg = checkStatusCode(res.data.status_code);
    //       this.$toastError(msg);
    //     }
    //   }).catch(error => {
    //     console.log('Error:', error);
    //   });
    // },
  },
  
}
</script>