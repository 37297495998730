<template>
  <div class="modal fade" id="editNoteModal" tabindex="-1" aria-labelledby="editNoteLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                <Field name="date" :rules="{ required: true }" v-slot="{ field }" id="date">
                  <Datepicker1 class="datepicker-here form-control digits py-0" placeholder="Date"
                    v-model="localDetail.publish_at" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                  <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-6 mb-3">
                <label for="version" class="col-form-label fw-semibold">Version</label>
                <p class="mb-0 text-secondary">{{ localDetail.version }}</p>
              </div>
              <div class="col-md-12">
                <label for="note" class="col-form-label fw-semibold">Note</label>
                <Field name="note" as="textarea" class="form-control" placeholder="Add text" id="note" v-model="localDetail.note" />
                <ErrorMessage name="note" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      localDetail: { ...this.detail }
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker1,
  },
  mounted() {
    this.$nextTick(() => {
      // 手動設置字段值，避免驗證 bug
      this.$refs.form.setFieldValue('date', this.localDetail.publish_at);
    });
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/terms_of_service/update`;
      const payload = {
        id: this.$route.params.termsId,
        publish_at: this.localDetail.publish_at,
        note: this.localDetail.note
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  props: ['detail'],
  emits: ['hide', 'update']
}
</script>