<template>
  <div class="modal fade" id="resetModal" tabindex="-1" aria-labelledby="resetLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <Form @submit.prevent="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Reset</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
             <div class="ms-3 mb-2 radio radio-primary">
               <input :id="type === 'gateway' ? 'gateway' : 'sensor'" :value="type === 'gateway' ? 'gateway' : 'sensor'"
               type="radio" name="reset" v-model="resetType" >
               <label :for="type === 'gateway' ? 'gateway' : 'sensor'" class="h6">{{ type === 'gateway' ? 'Gateway' : 'Sensor' }}</label>
              </div>
              <div class="ms-3 radio radio-primary">
                <input id="all" type="radio" name="reset" v-model="resetType" value="all">
                <label for="all" class="h6 mb-0">Gateway and Sensor</label>
              </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Reset</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resetType: this.type === 'gateway' ? 'gateway' : 'sensor',
    }
  },
  props: ['type'],
  methods: {
    submitForm() {
      this.$emit('mqttReset', this.resetType);
    }
  },
  emits: ['mqttReset']
}
</script>