<template>
  <div class="modal fade" id="switchCheckModal" tabindex="-1" aria-labelledby="switchCheckLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form ref="form" @submit.prevent="$emit('handleSwitch')">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <vue-feather type="alert-triangle" size="42" class="text-danger mb-3"></vue-feather>
            <h5>Are you sure you want to {{ text }}?</h5>
          </div>
          <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-light" @click.prevent="$emit('hide')">Cancel</button>
              <button type="submit" class="btn btn-primary">Confirm</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'continue'
    }
  },
  emits: ['handleSwitch', 'hide'],
}
</script>
