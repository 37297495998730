<template>
  <li class="profile-nav onhover-dropdown pe-0">
    <div class="media profile-media align-items-center">
      <!-- <img
        class="b-r-10"
        src="@/assets/images/dashboard/profile.png"
        alt=""
      /> -->
      <img class="img-30 rounded-circle" :src="memberInfo.profile || 'https://fakeimg.pl/20x20/?text=20x20'" alt="profile">
      <div class="media-body">
        <span>{{ memberInfo.first_name }} {{ memberInfo.last_name }}</span>
        <p class="mb-0 mt-1 font-roboto">
          {{ memberInfo.role }}
          <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click.prevent="emitModal('changePassword')">
        <a href="javascript:;" >
          <vue-feather type="user"></vue-feather><span>Change Password</span>
        </a>
      </li>
      <!-- <li>
        <router-link :to="'/email/readMail'"><vue-feather type="mail"></vue-feather><span>Inbox</span></router-link>
      </li>
      <li>
        <router-link :to="'/app/task'"><vue-feather type="file-text"></vue-feather><span>Taskboard</span></router-link>
      </li>
      <li>
        <router-link :to="'/pages/search'"><vue-feather type="settings"></vue-feather><span>Settings</span></router-link>
      </li> -->
      <li @click.prevent="emitModal('logout')">
        <a href="javascript;;" ><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
      </li>
    </ul>
  </li>
</template>

<script>
import Modalfat from "../../pages/uikits/modal/Modalfat.vue"
import { mapState } from 'vuex';
import eventBus from '@/methods-gc/eventBus';

export default {
  name: 'Profile',
  computed: {
    ...mapState('user', ['memberInfo']),
  },
  components:{
      Modalfat,
   },
  methods: {
    emitModal(name) {
      eventBus.emit('show-modal', name);
    }
  }
};
</script>