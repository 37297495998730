<template>
  <Breadcrumbs title="Staff Management"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
         <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3 flex-wrap flex-xl-nowrap">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.country" 
              :options="options.country" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="Country" 
              @update:modelValue="val => selectChange(val, 'country')">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.state" 
              :options="options.state" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="State" 
              @update:modelValue="val => selectChange(val, 'state')">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.city" 
              :options="options.city" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="City">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.service" 
              :options="options.service" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="Service">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" @click="showModal('addStaff')" class="btn btn-primary text-nowrap">+ Add New Staff</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Staff</th>
                  <th scope="col">Country</th>
                  <th scope="col">State</th>
                  <th scope="col">City</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <!-- 指派房子的數量，detail 右邊為指派維修單的數量 -->
                  <th scope="col">Service Case</th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in staffList" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td class="d-flex align-items-center gap-2">
                    <img class="img-40 rounded-circle" :src="item.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                    <div>
                      <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                      <p class="mb-0">{{ item.email }}</p>
                    </div>
                  </td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.state }}</td>
                  <td>{{ item.city }}</td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" 
                        @click.prevent="confirmSwitch(item)">
                      </div>
                    </div>
                  </td>
                  <td >
                    <div v-if="item.service_case">
                      <span class="txt-primary">{{ item.service_case.assigned }} Assigned / </span>
                      <span class="text-danger">{{ item.service_case.pending }} Pending</span>
                    </div>
                    <span v-else>Unassigned</span>
                  </td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link :to="{ name: 'staffViewDetail', params: { staffId: item.id } }">
                      <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addStaff v-if="shown.addStaff" ref="addStaff" @hide="hideModal('addStaff')" @getList="getList" />
  <switchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchStaffStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addStaff from '@/components-gc/modal/staffManagement/addStaff.vue'
import switchCheck from '@/components-gc/modal/switchCheck.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      staffList: [],
      listRequest: {
        country: "",
        state: "",
        city: "",
        search: "",
        service: "",
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
        enabled: ""
      },
      total: 0,
      options: {
        country: [],
        state: [],
        city: [],
        service: ['All','Pending', 'Done']
      },
      modals: {},
      shown: {
        addStaff: false,
        switchCheck: false,
      },
      switchItem: null,
      previousListRequest: {},
    }
  },
  components: { 
    addStaff,
    Pagination,
    switchCheck
  },
  mounted() {
    this.getCountry();
    this.getList();
  },
  watch: {
    'listRequest.search'() {
      this.listRequest.page = 1;
      this.getList();
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'staff' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
          this.options.country.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'staff', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
          this.options.state.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'staff', country: this.listRequest.country, state: this.listRequest.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
          this.options.city.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.options.state = [];
        this.options.city = [];
        if(val && val !== 'All') {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = ''; 
        this.options.city = [];
        if(val && val !== 'All') {
          this.getCity();
        }
      }
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });
      
      const api = `${process.env.VUE_APP_PATH}/staff/list`;
      this.axios.post(api, params)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.staffList = res.data.data;
          this.total = res.data.total;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchStaffStatus() {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/staff/switch`;
      this.axios.post(api, {
        user_id: this.switchItem.id,
        enable: !this.switchItem.status
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.getList();
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    clearAll() {
      const isAllCleared = !this.listRequest.country && !this.listRequest.state && !this.listRequest.city && !this.listRequest.service;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, country: '', state: '', city: '', service: '' };
        this.options.state = [];
        this.options.city = [];
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
  }
}
</script>