<template>
  <div class="modal fade" id="editContentModal" tabindex="-1" aria-labelledby="editContentLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ title }} Notifications Content</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-12 col-md-6 mb-md-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                  <Field name="date" :rules="{ required: true }" v-slot="{ field }">
                     <Datepicker1 class="datepicker-here form-control digits py-0" position="left" placeholder="Date"
                     v-model="localDetail.publish_at" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                    <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-12 col-md-6 mb-md-3">
                <label for="type" class="col-form-label fw-semibold">Type</label>
                <Field name="type" :rules="{ required: true }" v-slot="{ field }">
                  <multiselect v-model="localDetail.type" v-bind="field" 
                    :options="type" 
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :allow-empty="false"
                    placeholder="Type"
                  ></multiselect>
                  <ErrorMessage name="type" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-12">
                <label for="content" class="col-form-label fw-semibold">Content</label>
                <div class="position-relative">
                  <Field name="content" as="textarea" class="form-control" placeholder="Content" id="content" v-model="localDetail.content" />
                  <ErrorMessage name="content" class="text-danger" />
                </div>
              </div>
              <div class="col-12">
                <label for="link" class="col-form-label fw-semibold">Link URL</label>
                <div class="position-relative">
                  <Field name="link" type="text" class="form-control" placeholder="Link" id="link" v-model="localDetail.url" />
                  <ErrorMessage name="link" class="text-danger" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-end">
            <!-- <div>
              <button type="button" class="btn btn-secondary me-2" @click.prevent="handleDelete">Delete</button>
              <button type="button" class="btn btn-outline-secondary" @click.prevent="publish">Directly Publish</button>
            </div> -->
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datepicker from "vue3-datepicker";
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      type: ['Official', 'Events', 'Specify', 'Staff'],
      localDetail: { ...this.notificationDetail }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 手動設置字段值，避免驗證 bug
      this.$refs.form.setFieldValue('date', this.localDetail.publish_at);
      this.$refs.form.setFieldValue('type', this.localDetail.type);
    });
    this.localDetail.type = this.$capitalizeFirst(this.localDetail.type);
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    Datepicker1
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/notifactions/update`;
      const payload = {
        id: this.localDetail.id,
        publish_at: this.localDetail.publish_at,
        type: this.localDetail.type.toLowerCase(),
        content: this.localDetail.content,
        url: this.localDetail.url
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Notification updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  props: ['notificationDetail', 'title'],
  emits: ['hide', 'update']
}
</script>