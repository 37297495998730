// src/methods-gc/vue-toastify.js
import { toast } from 'vue3-toastify';

export default {
  install: (app) => {
    const defaultOptions = {
      theme: 'colored',
      transition: 'slide',
      dangerouslyHTMLString: true,
      autoClose: 3000,
    };

    const toastSuccess = (message, options = {}) => {
      if (!message) return;
      toast(message, {
        ...defaultOptions,
        type: 'success',
        ...options,
      });
    };

    const toastError = (message, options = {}) => {
      if (!message) return;
      toast(message, {
        ...defaultOptions,
        type: 'error',
        ...options,
      });
    };

    const toastWarning = (message, options = {}) => {
      if (!message) return;
      toast(message, {
        ...defaultOptions,
        type: 'warning',
        ...options,
      });
    };

    const toastLoading = (message, options = {}) => {
      if (!message) return;
      return toast.loading(message, {
        ...defaultOptions,
        ...options,
      });
    };

    const toastUpdate = (id, message, options = {}) => {
      if (!id || !message) return;
      toast.update(id, {
        render: message,
        ...defaultOptions,
        ...options,
        isLoading: false,
      });
    };

    app.config.globalProperties.$toastSuccess = toastSuccess;
    app.config.globalProperties.$toastError = toastError;
    app.config.globalProperties.$toastWarning = toastWarning;
    app.config.globalProperties.$toastLoading = toastLoading;
    app.config.globalProperties.$toastUpdate = toastUpdate;
  },
};
