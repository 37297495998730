export default {
  install(app) {
    // 將字串第一個字母大寫
    app.config.globalProperties.$capitalizeFirst = (string) => {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return;
      }
    };

    // 將數字加千位符
    app.config.globalProperties.$formatNumber = (number) => {
      if (typeof number === 'number') {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return;
      }
    };
  }
}
