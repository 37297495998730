<template>
  <div class="modal fade" id="addReportModal" tabindex="-1" aria-labelledby="addReportLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add Report</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div>
              <label for="description" class="col-form-label fw-semibold">Description</label>
              <div class="position-relative">
                <Field name="description" as="textarea" class="form-control" placeholder="Description" 
                id="description" v-model="description" rules="required"  rows="3"/>
                <ErrorMessage name="description" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-end">
              <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      description: ''
    }
  },
  props: ['houseId'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/house/create_report`;
      const payload = {
        house_id: this.houseId,
        description: this.description
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Report has been created successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  emits: ['hide', 'update']
}
</script>