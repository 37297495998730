import { createWebHistory, createRouter } from "vue-router";
import store from '../store/index'; 
import Body from '../components/body';
import Dashboard from '../pages-gc/dashboard';
import login from '../auth/login';
import Error404 from '../pages/error/error404';
// User Management
import UserManagementIndex from '../pages-gc/userManagement/index';
import UserDetailLayout from '../pages-gc/userManagement/detailLayout';
import UserViewDetail from '../pages-gc/userManagement/viewDetail';
import UserHouseDetailLayout from '../pages-gc/userManagement/houseDetail/layout.vue';
// Staff Management
import StaffManagementIndex from '../pages-gc/staffManagement/index';
import StaffDetailLayout from '../pages-gc/staffManagement/detailLayout';
import StaffViewDetail from '../pages-gc/staffManagement/viewDetail';
import StaffCaseLayout from '../pages-gc/staffManagement/caseLayout.vue';
import StaffCaseDetail from '../pages-gc/staffManagement/caseDetail.vue';
import StaffHouseDetailLayout from '../pages-gc/staffManagement/reportDetail/layout.vue';
// House List
import HouseListIndex from '../pages-gc/houseList/index';
import HouseDetailLayout from '../pages-gc/houseList/houseDetail/layout.vue';

// Device
import DeviceGateway from '../pages-gc/device/gateway.vue';
import DeviceSensor from '../pages-gc/device/sensor.vue';
import DeviceList from '../pages-gc/device/deviceList.vue';
import SensorLayout from '../pages-gc/device/sensorLayout.vue';
import MoistureLevel from '../pages-gc/device/moistureLevel.vue';
import Temperature from '../pages-gc/device/temperature.vue';
import Humidity from '../pages-gc/device/humidity.vue';
import Files from '../pages-gc/device/files.vue';

// Notifications
import NotificationsIndex from '../pages-gc/notifications/index';
import NotificationsDetail from '../pages-gc/notifications/detail';

// Device Management
import DeviceManagement from '../pages-gc/deviceManagement.vue';

// Setting
import TermsOfService from '../pages-gc/setting/termsOfService.vue';
import TermsDetail from '../pages-gc/setting/termsDetail.vue'; 
import MemberCriteria from '../pages-gc/setting/memberCriteria.vue';
import MemberDetail from '../pages-gc/setting/memberDetail.vue';
import ContactToService from '../pages-gc/setting/contactToService.vue';
import ContactToServiceDetail from '../pages-gc/setting/contactToServiceDetail.vue';
import Information from '../pages-gc/setting/information.vue';
import InformationDetail from '../pages-gc/setting/informationDetail.vue';
import AboutUs from '../pages-gc/setting/aboutUs.vue';
import AboutUsDetail from '../pages-gc/setting/aboutUsDetail.vue';
import Faq from '../pages-gc/setting/faq.vue';
import FaqDetail from '../pages-gc/setting/faqDetail.vue';
import Administrator from '../pages-gc/setting/administrator.vue';
import AdministratorDetail from '../pages-gc/setting/administratorDetail.vue';
import RoleSetting from '../pages-gc/setting/roleSetting.vue';
import RoleSettingDetail from '../pages-gc/setting/roleSettingDetail.vue';
import Update from '../pages-gc/setting/update.vue';
import UpdateDetail from '../pages-gc/setting/updateDetail.vue';

import DeleteAccountRequest from '../pages-gc/deleteAccountRequest.vue';
import NodeLogList from '../pages-gc/nodeLogList.vue';
import DeleteAccount from '../pages-gc/deleteAccount.vue';


// template
/* Dashboards */
// import Default from '../pages/dashboard/default';
// import Ecommerce from '../pages/dashboard/ecommerce';
// import Online from  '../pages/dashboard/online'
// import Crypto from '../pages/dashboard/crypto'
// import Social from '../pages/dashboard/social.vue'
// // Widgets
// import General from '../pages/widgets/general'
// import Chart from '../pages/widgets/chart'
// // pageLayout
// import indexBoxed from '../pages/pageLayout/boxed/indexBoxed.vue'
// import indexRTL from '../pages/pageLayout/RTL/indexRTL.vue'
// import indexDarklayout from '../pages/pageLayout/darklayout/indexDarklayout.vue'
// import indexFooterdark from '../pages/pageLayout/footerdark/indexFooterdark.vue'
// import indexFooterfixed from '../pages/pageLayout/footerfixed/indexFooterfixed.vue'
// import indexFooterlight from '../pages/pageLayout/footerlight/indexFooterlight.vue'
// import indexHidenavscroll from '../pages/pageLayout/hidenavscroll/indexHidenavscroll.vue'
// // project
// import createProject from '../pages/project/createproject/createProject.vue'
// import indexList from '../pages/project/projectlist/indexList.vue'
// /* File Manager  */
// import FileManager from '../pages/filemanager';
// // kanban
// import Kanban from '../pages/kanban/indexKanban.vue'
// // ecoomerce
// import indexProduct from '../pages/ecommerce/product/indexProduct.vue'
// import productPage from '../pages/ecommerce/productpage/productPage.vue'
// import productList from '../pages/ecommerce/list/productList.vue'
// import paymentDetails from '../pages/ecommerce/payment/details/paymentDetails.vue'
// import orderHistory from '../pages/ecommerce/order/orderHistory.vue'
// import invoiceView from '../pages/ecommerce/invoice/invoiceView.vue'
// import cartView from '../pages/ecommerce/cart/cartView.vue'
// import wishlistView from '../pages/ecommerce/wishlist/wishlistView.vue'
// import checkoutView from '../pages/ecommerce/checkout/checkoutView.vue'
// import pricingView from '../pages/ecommerce/pricing/pricingView.vue'
// // mail
// import readMail from '../pages/email/readMail/readMail.vue'
// import composeView from '../pages/email/compose/composeView.vue'
// // chat
// import chatApp from '../pages/chat/chatApp/chatApp.vue'
// import videoChat from '../pages/chat/videoChat/videoChat.vue'
// // user
// import userProfile from '../pages/users/profile/userProfile.vue'
// import userCards from '../pages/users/cards/userCards.vue'
// import userEdit from '../pages/users/edit/userEdit.vue'
// // forms
// import formValidation from "../pages/forms/formValidetion/formValidation"
// import base_Input from "../pages/forms/baseInput/base_Input"
// import checkbox_radio from "../pages/forms/Checkbox&Radio/checkbox_radio"
// import input_groups from "../pages/forms/InputGroup/input_groups"
// import megaOptions from "../pages/forms/megaOptions/megaOptions"

// // form widgets
// import select2 from "../pages/formWidgets/select2/select2Page.vue"
// import switch_From from "../pages/formWidgets/switch/switch_From"
// import touchspin_Form from "../pages/formWidgets/touchspin/touchspin_Form"
// import typeahead_Form from "../pages/formWidgets/typeahead/typeahead_Form"
// import clipboard_Form from "../pages/formWidgets/clipboard/clipboard_Form"
// import datepicker from "../pages/formWidgets/datepicker/datePicker.vue"

// // form layput
// import defaultforms from '../pages/formLayout/default/defaultFotrm.vue'
// import form_wizard2 from "../pages/formLayout/formWizrd2/form_wizard.vue"
// import form_wizard1 from '../pages/formLayout/formWizard1/form_wizard2.vue'
// import form_wizard3 from "../pages/formLayout/formWizard3/form_wizard1.vue"

// uikits
// import Scrollable from "../pages/advance/scollable/scrollable_advance.vue";
// import Sweetalert from "../pages/advance/sweetalert/sweetAert.vue";
// import Tree from "../pages/advance/tree/treeView.vue";
// import Rating from "../pages/advance/rating/ratingView.vue";
// import Pagination from "../pages/advance/pagination/pagination_advance.vue";
// import Ribbons from "../pages/advance/ribbons/ribbons_advance.vue";
// import Tour from "../pages/advance/tour/tourView.vue";

// import Breadcrumb from "../pages/advance/breadcrumbs/breadCrumb.vue";

// import Sticky from "../pages/advance/sticky/stickyView.vue";

// import ImageCropper from "../pages/advance/image_cropper/cropper_advance.vue";
// import Notify from '../pages/advance/notify/toaster_advance.vue'
// import dropzone from '../pages/advance/dropzone/dropzoneView.vue';
// import animatedModal from '../pages/advance/animatedModal/animatedModal.vue'
// import owlCarousel from '../pages/advance/owlCarousel/owlCarousel.vue' 
// import Rangeslider from '../pages/advance/range/rangeView.vue'
// import BasicCard from '../pages/advance/basicCard/basicCard.vue'
// import CreativeCard from '../pages/advance/creativeCard/creative_card.vue'
// import DraggableCard from '../pages/advance/draggableCard/draggableCard.vue'
// import TabbedCard from '../pages/advance/tabbedCard/tabbed_card.vue'
// import timeLine from '../pages/advance/timeLine/timeLine.vue'
// /* FAQ */
// import Faq from '../pages/faq/faqIndex.vue';
// // uikits
// import alert from '../pages/uikits/alert';
// import Helperclasses from '../pages/uikits/helper_classes';
// import Avatars from '../pages/uikits/avatars';
// import Grid from '../pages/uikits/grid';
// import Tagpills from '../pages/uikits/tag_pills';
// import Typography from '../pages/uikits/typography';
// import Progressbar from '../pages/uikits/progress_bar';
// import Modal from '../pages/uikits/modal';
// import Popover from '../pages/uikits/popover';
// import Tooltip from '../pages/uikits/tooltip';
// import Spinners from '../pages/uikits/loader';
// import Dropdown from '../pages/uikits/dropdown';
// import Accordion from '../pages/uikits/accordion';
// import Boxshadow from '../pages/uikits/box_shadow';
// import Lists from '../pages/uikits/lists';
// import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
// import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";
// // animation
// import animate from '../pages/animation/animate/animatePage.vue'
// import aos from '../pages/animation/aos/aosPage.vue'
// import scroll from '../pages/animation/scroll/scrollPage.vue'
// import wow from '../pages/animation/wow/wowPage.vue'
// import tilt from '../pages/animation/tilt.vue'
// // builder
// import form1 from '../pages/builder/formBuilder1.vue'
// import form2 from '../pages/builder/formBuilder2.vue'
// /* icons */
// import Flag from '../pages/icons/flag';
// import Ico from '../pages/icons/ico_icon';
// import Themify from '../pages/icons/themify_icon';
// import Fontawesome from '../pages/icons/font_awesome';
// import FeatherIcon from '../pages/icons/feather_icon';
// import WhetherIcon from '../pages/icons/weather';
// /* Buttons */
// import Default_buttons from '../pages/button/Default/default_button.vue';
// import Flat_buttons from '../pages/button/Flat/flat_button.vue';
// import Edge_buttons from '../pages/button/Edge/edge_button.vue';
// import Raised_buttons from '../pages/button/Raised/raised_button.vue';
// import Group_buttons from '../pages/button/ButtonGroup/button_group.vue'; 
// /*charts*/ 
// import google_chart from "../pages/charts/googleChart/google_chart"
// import apex_chart from "../pages/charts/ApexChart/apex_chart"
//  import chartist_chart from "../pages/charts/chartist/chartist_chart.vue"
// // knowledge
// import knowledge from '../pages/Knowledgebase/index.vue'
// import category_knowledge from '../pages/Knowledgebase/categoryKnowledge.vue'
// import detail_knowledge from '../pages/Knowledgebase/detailsKnowledge.vue' 
// // error
// import Error400 from '../pages/error/error400';
// import Error401 from '../pages/error/error401';
// import Error403 from '../pages/error/error403';
// import Error404 from '../pages/error/error404';
// import Error500 from '../pages/error/error500';
// import Error503 from '../pages/error/error503';

// /* Support Ticket */
// import Support from '../pages/support/support.vue';

// /* Editor */
// import SimpleEditor from '../pages/editor/simple_editor';
// import CkEditor from '../pages/editor/ckeditor.vue';
// /* Maps */
// import GoogleMaps from '../pages/maps/google_map.vue';
// import VueLeaflet from '../pages/maps/vue_leaflet.vue';
// // learning
// import learning_list from "../pages/learning/learninglist/learning_list"
// import coursedetailed from "../pages/learning/courseDetailed"
// // job Search
// import job_list from "../pages/job/job-list/job_list"
// import job_details from "../pages/job/job_details/job_details"
// import job_apply from "../pages/job/job_apply/job_apply"
// import job_card from "../pages/job/job_card/cardJob.vue"
// // blog
// import blog_detail from "../pages/blog/blog-details/blog_detail"
// import blog_single from "../pages/blog/blog-single/blog_single"
// import blog_add from "../pages/blog/blog-add/addBlog.vue"
// // GALLERY
// import grid_gallery from "../pages/gallery/grid_gallery"
// import grid_desc from "../pages/gallery/grid_desc"
// import hover_gallery from "../pages/gallery/hover-gallery/hover_gallery"
// import masonry_gallery from "../pages/gallery/masonry-gallery/masonry_gallery"
// import masonary_desc from "../pages/gallery/masonary_desc.vue"
// /* Authentication */
// import LoginOne from '../pages/authentication/login_one';
// import LoginTwo from '../pages/authentication/login_two';
// import LoginValidate from '../pages/authentication/login_validation.vue';
// import LoginTooltip from '../pages/authentication/login_tooltip.vue'
// import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

// import RegisterImage from '../pages/authentication/register_image';
// import RegisterImage2 from '../pages/authentication/register_image2';
// import Unlock from '../pages/authentication/unlock';
// import ForgetPassword from '../pages/authentication/forget_password';
// import ResetPassword from '../pages/authentication/reset_password';
// import Maintenance from '../pages/authentication/maintenance.vue'
// /* Auth */
// import login from '../auth/login';
// import Register from '../auth/register';
// // comingsoon
// import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
// import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';
// import ComingsoonVideo from '../pages/comingsoon/comingsoon_video';
// // SAMPLE PAGE
// import sample_page from "../pages/sample_page"
// /* bootstrap tables */
// import BootstrapBasic from "../pages/tables/bootstrapTable/basicTables/basic_tables.vue";
// import tableComponent from "../pages/tables/bootstrapTable/stylingtable/styling_table.vue"
// import DatatableBasic from "../pages/tables/dataTable/BasicInit/basic_Init.vue"
// // searchPage
// import serchIndex from "../pages/search/serchIndex.vue"
// // bookmark
// import Bookmark from "../pages/bookmark/bookMark.vue"
// // contact 
// import contactView from "../pages/contact/contactView.vue"
// // task
// import Task from "../pages/task/indexPage.vue"
// // calendar
// import Calendar from "../pages/calenderView.vue"
// // social app
// import socialPage from "../pages/socialApp/socialApp.vue"
// // todo
// import Todo from "../pages/todo/index.vue"
// import { children } from "bootstrap/js/dist/dom/selector-engine";

const routes = [
    {
      path: '/',
      component: Body,
      children: [
        {
          path: '',
          name: 'defaultRoot',
          component: Dashboard,
          meta: {
            title: ' Katcher',
          }
        },
  
      ]
    },
    {
        path: '/auth',
        children: [
        {
          path: 'login',
          name: 'Login 1',
          component: login,
          meta: {
            title: ' Login',
          },
        }
        ]
      },
    {
      path:"/user-management",
      component: Body,
      children:[
        {
          path:"index",
          name:"userManagementIndex",
          component: UserManagementIndex,
          meta: {
            title: ' User Management | Katcher',
          }
        },
        {
          path:"view-detail/:userId",
          name:"userDetailLayout",
          component: UserDetailLayout,
          meta: {
            title: ' View Detail | Katcher',
          },
          children: [
            {
              path:"",
              name:"userManagementViewDetail",
              component: UserViewDetail,
            },
            {
              path:"house-detail/:houseId",
              name:"userHouseDetailLayout",
              component: UserHouseDetailLayout,
              meta: {
                title: ' House Detail | Katcher',
              },
              children: [
                {
                  path: "",
                  name:"userDeviceList",
                  component: DeviceList
                },
                {
                  path: "gateway/:gatewayId",
                  name:"userGateway",
                  component: DeviceGateway
                },
                {
                  path: "sensor/:sensorId",
                  name:"userSensorLayout",
                  component: SensorLayout,
                  children: [
                    {
                      path: "",
                      name:"userSensor",
                      component: DeviceSensor
                    },
                    {
                      path: "moisture-level",
                      name:"userMoistureLevel",
                      component: MoistureLevel
                    },
                    {
                      path: "temperature",
                      name:"userTemperature",
                      component: Temperature
                    },
                    {
                      path: "humidity",
                      name:"userHumidity",
                      component: Humidity
                    },
                    {
                      path: "files",
                      name:"userFiles",
                      component: Files
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/staff-management",
      component: Body,
      children:[
        {
          path:"index",
          name:"staffManagementIndex",
          component: StaffManagementIndex,
          meta: {
            title: ' Staff Management | Katcher',
          }
        },
        {
          path:"view-detail/:staffId",
          name:"staffViewDetail",
          component: StaffViewDetail,
          meta: {
            title: ' View Detail | Katcher',
          },
          children: [
            {
              path:"",
              name:"staffDetailLayout",
              component: StaffDetailLayout,
            },
          ]
        },
        {
          path:"case-detail/:caseId",
          name:"StaffCaseLayout",
          component: StaffCaseLayout,
          meta: {
            title: ' Case Detail | Katcher',
          },
          children: [
            {
              path: "",
              name:"staffCaseDetail",
              component: StaffCaseDetail
            },
            {
              path:"house-detail/:houseId",
              name:"staffHouseDetailLayout",
              component: StaffHouseDetailLayout,
              meta: {
                title: ' House Detail | Katcher',
              },
              children: [
                {
                  path: "",
                  name:"staffDeviceList",
                  component: DeviceList
                },
                {
                  path: "gateway/:gatewayId",
                  name:"staffGateway",
                  component: DeviceGateway
                },
                {
                  path: "sensor/:sensorId",
                  name:"staffSensorLayout",
                  component: SensorLayout,
                  children: [
                    {
                      path: "",
                      name:"staffSensor",
                      component: DeviceSensor
                    },
                    {
                      path: "moisture-level",
                      name:"staffMoistureLevel",
                      component: MoistureLevel
                    },
                    {
                      path: "temperature",
                      name:"staffTemperature",
                      component: Temperature
                    },
                    {
                      path: "humidity",
                      name:"staffHumidity",
                      component: Humidity
                    },
                    {
                      path: "files",
                      name:"staffFiles",
                      component: Files
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/house-list",
      component: Body,
      children:[
        {
          path:"index",
          name:"houseListIndex",
          component: HouseListIndex,
          meta: {
            title: ' House List | Katcher',
          }
        },
        {
          path:"house-detail/:houseId",
          name:"houseDetailLayout",
          component: HouseDetailLayout,
          meta: {
            title: ' House Detail | Katcher',
          },
          children: [
            {
              path: "",
              name:"houseDeviceList",
              component: DeviceList
            },
            {
              path: "gateway/:gatewayId",
              name:"houseGateway",
              component: DeviceGateway
            },
            {
              path: "sensor/:sensorId",
              name:"houseSensorLayout",
              component: SensorLayout,
              children: [
                {
                  path: "",
                  name:"houseSensor",
                  component: DeviceSensor
                },
                {
                  path: "moisture-level",
                  name:"houseMoistureLevel",
                  component: MoistureLevel
                },
                {
                  path: "temperature",
                  name:"houseTemperature",
                  component: Temperature
                },
                {
                  path: "humidity",
                  name:"houseHumidity",
                  component: Humidity
                },
                {
                  path: "files",
                  name:"houseFiles",
                  component: Files
                },
              ]
            },
          ]
        },
      ]
    },
    {
      path:"/notifications",
      component: Body,
      children:[
        {
          path:"index",
          name:"notificationsIndex",
          component: NotificationsIndex,
          meta: {
            title: ' Notifications | Katcher',
          }
        },
        {
          path:"notifications-detail/:notificationsId",
          name:"notificationsDetail",
          component: NotificationsDetail,
          meta: {
            title: ' Notifications Detail | Katcher',
          }
        }
      ]
    },
    {
      path:"/device-management",
      component: Body,
      children:[
        {
          path:"",
          name:"deviceManagement",
          component: DeviceManagement,
          meta: {
            title: ' Device Management | Katcher',
          }
        },
      ]
    },
    {
      path:"/setting",
      component: Body,
      children:[
        {
          path:"terms-of-service",
          name:"termsOfService",
          component: TermsOfService,
          meta: {
            title: ' Terms of Service | Katcher',
          }
        },
        {
          path:"terms-of-service/detail/:termsId",
          name:"termsDetail",
          component: TermsDetail,
          meta: {
            title: ' Terms of Service | Katcher',
          }
        },
        {
          path:"member-criteria",
          name:"memberCriteria",
          component: MemberCriteria,
          meta: {
            title: ' Member Criteria | Katcher',
          }
        },
        {
          path:"member-criteria/detail/:memberId",
          name:"memberDetail",
          component: MemberDetail,
          meta: {
            title: ' Member Criteria Detail | Katcher',
          }
        },
        {
          path:"contact-to-service",
          name:"contactToService",
          component: ContactToService,
          meta: {
            title: ' Contact To Service | Katcher',
          }
        },
        {
          path:"contact-to-service/detail/:contactId",
          name:"contactToServiceDetail",
          component: ContactToServiceDetail,
          meta: {
            title: ' Contact To Service Detail | Katcher',
          }
        },
        {
          path:"information",
          name:"information",
          component: Information,
          meta: {
            title: ' Information | Katcher',
          }
        },
        {
          path:"information/detail/:informationId",
          name:"informationDetail",
          component: InformationDetail,
          meta: {
            title: ' Information Detail | Katcher',
          }
        },
        {
          path:"about-us",
          name:"aboutUs",
          component: AboutUs,
          meta: {
            title: ' About Us | Katcher',
          }
        },
        {
          path:"about-us/detail/:aboutId",
          name:"aboutUsDetail",
          component: AboutUsDetail,
          meta: {
            title: ' About Us Detail | Katcher',
          }
        },
        {
          path:"faq",
          name:"faq",
          component: Faq,
          meta: {
            title: ' FAQ | Katcher',
          }
        },
        {
          path:"faq/detail/:faqId",
          name:"faqDetail",
          component: FaqDetail,
          meta: {
            title: ' FAQ Detail | Katcher',
          }
        },
        {
          path:"administrator",
          name:"administrator",
          component: Administrator,
          meta: {
            title: ' Administrator | Katcher',
          }
        },
        {
          path:"administrator/detail/:administratorId",
          name:"administratorDetail",
          component: AdministratorDetail,
          meta: {
            title: ' Administrator Detail | Katcher',
          }
        },
        {
          path:"role-setting",
          name:"roleSetting",
          component: RoleSetting,
          meta: {
            title: ' Role Setting | Katcher',
          }
        },
        {
          path:"role-setting/detail/:roleId",
          name:"roleSettingDetail",
          component: RoleSettingDetail,
          meta: {
            title: ' Role Setting Detail | Katcher',
          }
        },
        {
          path:"update",
          name:"update",
          component: Update,
          meta: {
            title: ' Update | Katcher',
          }
        },
        {
          path:"update/detail/:updateId",
          name:"updateDetail",
          component: UpdateDetail,
          meta: {
            title: ' Update Detail | Katcher',
          }
        },
      ]
    },
    {
      path:"/delete-account-request",
      component: Body,
      children:[
        {
          path:"",
          name:"deleteAccountRequest",
          component: DeleteAccountRequest,
          meta: {
            title: 'Delete Account Request | Katcher',
          }
        },
      ]
    },
    {
      path:"/node-log-list",
      component: Body,
      children:[
        {
          path:"",
          name:"nodeLogList",
          component: NodeLogList,
          meta: {
            title: 'Node Log List | Katcher',
          }
        },
      ]
    },
    // web view
     { 
      path: '/delete-account',
      name: 'deleteAccount',
      component: DeleteAccount,
      meta: {
          title: 'Delete Account | Katcher',
        }
    }, 
    { 
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: Error404,
      meta: {
          title: 'Error404 | Katcher',
      }
    }, 
]
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
    routes,
  })
  router.beforeEach((to, from, next) => {
    // remove modal backdrop if one exists
    let modalBackground = document.querySelectorAll('.modal-backdrop')
    if (modalBackground.length > 0) {
      modalBackground.forEach(backdrop => backdrop.remove());
    }

    if(to.meta.title)
      document.title = to.meta.title;

    // Check if accessToken is null, except for DeleteAccount page
    const accessToken = store.state.user.accessToken;
    if (!accessToken && to.path !== '/auth/login' && to.name !== 'deleteAccount') {
      return next('/auth/login');
    }

    next();
  });
  export default router