<template>
  <nav class="mt-3" v-if="totalPages > 1">
    <ul class="pagination pagination-primary pagination-sm justify-content-end">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="javascript:void(0)" @click="changePage(currentPage - 1)">Previous</a>
      </li>
      <li class="page-item" v-for="page in pagesToShow" :key="page" :class="{ active: currentPage === page, disabled: page === '...' }">
        <a class="page-link" v-if="page !== '...'" href="javascript:void(0)" @click.prevent="changePage(page)">{{ page }}</a>
        <span v-else class="page-link">...</span>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="javascript:void(0)" @click="changePage(currentPage + 1)">Next</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    pagesToShow() {
      const pages = [];
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= 4) {
          pages.push(1, 2, 3, 4, 5, '...', totalPages);
        } else if (currentPage >= totalPages - 3) {
          pages.push(1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
        } else {
          pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
        }
      }
      return pages;
    }
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages && page !== '...') {
        this.$emit('updatePage', page);
      }
    }
  },
  emits: ['updatePage']
}
</script>

<!-- <style scoped>
.page-item.disabled .page-link {
  pointer-events: none;
  cursor: default;
}
</style> -->