<template>
  <div class="modal fade" id="replaceModal" tabindex="-1" aria-labelledby="replaceLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Replace {{ $capitalizeFirst(type) }}</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <label for="text" class="mb-3">Series number</label>
            <div class="d-flex align-items-center gap-2">
              <p class="mb-0">{{ type === 'gateway' ? 'G' : 'N' }}</p>
              <p class="mb-0">-</p>
              <div>
                <Field name="column1" type="text" class="form-control" placeholder="NAM" 
                rules="required" v-model="column1" />
              </div>
              <p class="mb-0">-</p>
              <div>
                <Field name="column2" type="text" class="form-control" placeholder="USA" 
                rules="required" v-model="column2" />
              </div>
              <p class="mb-0">-</p>
              <div>
                <Field name="column3" type="text" class="form-control" placeholder="000" 
                 rules="required" v-model="column3" />
              </div>
              <p class="mb-0">-</p>
              <div>
                <Field name="column4" type="text" class="form-control" placeholder="000" 
                 rules="required" v-model="column4" />
              </div>
              <p class="mb-0">-</p>
              <div>
                <Field name="column5" type="text" class="form-control" placeholder="000" 
                 rules="required" v-model="column5" />
              </div>
              <p class="mb-0">-</p>
              <div>
                <Field name="column6" type="text" class="form-control" placeholder="000" 
                 rules="required" v-model="column6" />
                </div>
              </div>
              <div class="mt-2">
                <ErrorMessage name="column1" class="d-block text-danger" />
                <ErrorMessage name="column2" class="d-block text-danger" />
                <ErrorMessage name="column3" class="d-block text-danger" />
                <ErrorMessage name="column4" class="d-block text-danger" />
                <ErrorMessage name="column5" class="d-block text-danger" />
                <ErrorMessage name="column6" class="d-block text-danger" />
              </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Replace</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
        column1: '',
        column2: '',
        column3: '',
        column4: '',
        column5: '',
        column6: '',
    }
  },
  props: ['type'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/house/device/${this.type}/replace`;
      const sPrefix = this.type === 'gateway' ? 'G' : 'N';
      const seriesNumber = `${sPrefix}-${this.column1}-${this.column2}-${this.column3}-${this.column4}-${this.column5}-${this.column6}`;

      const requestData = this.type === 'gateway' ? 
        { gateway_id: this.$route.params.gatewayId, gid: seriesNumber } : 
        { sensor_id: this.$route.params.sensorId, nid: seriesNumber };

      this.axios.post(api, requestData)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          if(this.type === 'gateway') {
            this.$emit('mqttReplace', this.seriesNumber);
          } else if(this.type === 'sensor') {
            this.$emit('mqttReplace');
          }
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  emits: ['hide', 'mqttReplace']
}
</script>