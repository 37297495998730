import { defineRule, configure } from 'vee-validate';
import { required, numeric, confirmed, email } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
// import zh_TW from '@vee-validate/i18n/dist/locale/zh_TW.json';

export function setValidation() {
  defineRule('required', required);
  defineRule('numeric', numeric);
  defineRule('email', email);
  defineRule('confirmed', confirmed);

  defineRule('ext', (value, allowedExtensions) => {
    console.log(allowedExtensions);
  if (!value) {
    return true;
  }
  const fileExtension = value.name.split('.').pop();
  if (!allowedExtensions.includes(fileExtension)) {
    return `file format must be ${allowedExtensions.join(' or ')}`;
  }
  return true;
});

  configure({
    generateMessage: localize({
      en: {
        messages: {
          required: '{field} is required',
          confirmed: 'passwords do not match',
        },
        names: {
          newPassword: 'new password',
          confirmPassword: 'confirm password',
          firstName: 'first name',
          lastName: 'last name',
          streetAddress: 'street address',
          houseName: 'house name',
          notificationsTitle: 'notifications title',
          accountType: 'account type',
        },
      },
    }),
  });

  // configure({
  //   generateMessage: localize({ zh_TW }),
  //   validateOnInput: true
  // });
  // setLocale('zh_TW');
}
