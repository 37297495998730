<template>
  <Breadcrumbs title="FAQ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end mb-3">
          <div>
            <button type="button" @click="showModal('addNew')" class="btn btn-primary">+ Add New</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Index</th>
                  <th scope="col">Title</th>
                  <th scope="col">Created at</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <router-link 
                      :to="{ name: 'faqDetail',  params: { faqId: item.id },
                        query: { 
                          page: listRequest.page,
                        }
                      }" 
                      class="me-3">
                       <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                    </router-link>
                    <a href="javascript:;" @click.prevent="confirmDelete(item.id)">
                      <i class="fa fa-trash-o fs-4" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addNew v-if="shown.addNew" ref="addNew" @hide="hideModal('addNew')" @update="getList" />
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'faq'"  @update="handleDelete" @hide="hideModal('deleteModal')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/setting/faq/addNew.vue';
import deleteModal from '@/components-gc/modal/delete.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      modals: {},
      list: [],
      listRequest: {
        page: 1,
        limit: 10,
      },
      total: 0,
      shown: {
        addNew: false,
        deleteModal: false,
      },
      faqId: null
    };
  },
  components: {
    addNew,
    deleteModal,
    Pagination
  },
  mounted() {
    if (this.$route.query) {
      this.listRequest.page = parseInt(this.$route.query.page) || 1;
    }
    this.getList();
  },
  methods: {
    getList() {
      const api = `${process.env.VUE_APP_PATH}/faq/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    confirmDelete(id) {
      this.faqId = id;
      this.showModal('deleteModal');
    },
    handleDelete() {
      this.hideModal('deleteModal');

      const api = `${process.env.VUE_APP_PATH}/faq/delete`;
      const payload = {
        id: this.faqId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Deleted successfully');
          this.hideModal('deleteModal');
          this.listRequest.page = 1;
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
};
</script>
