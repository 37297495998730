<template>
  <Breadcrumbs title="House List" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3 flex-wrap flex-xl-nowrap">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.country" 
              :options="options.country" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="Country" 
              @update:modelValue="val => selectChange(val, 'country')">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.state" 
              :options="options.state" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="State" 
              @update:modelValue="val => selectChange(val, 'state')">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.city" 
              :options="options.city" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="City">
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="listRequest.assigned" 
              :options="options.assigned" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="Assigned">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>
          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" @click="showModal('addHouse')" class="btn btn-primary text-nowrap">+ Add House</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col" @click="sortList('hid')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">House Id</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('hid') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('hid') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">House</th>
                  <th scope="col" @click="sortList('owner')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Owner</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('owner') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('owner') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('gateway')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Gateway</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('gateway') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('gateway') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('sensor')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Sensor</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('sensor') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('sensor') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Assigned</th>
                  <th scope="col">Creator</th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in houseList" :key="item.id">
                  <td>#{{ item.hid }}</td>
                  <td>
                    <div class="d-flex align-items-center gap-2">
                      <img class="img-50" :src="item.house.image || 'https://fakeimg.pl/50x50/?text=50x50'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.house.name }}</p>
                        <p class="mb-0">{{ item.house.address }}</p>
                      </div>
                    </div>
                  </td>
                  <td >
                    <div v-if="item.owner" class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.owner.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.owner.first_name }} {{ item.owner.last_name }}</p>
                        <p class="mb-0">{{ item.owner.code }} {{ item.owner.phone }}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{ item.gateway }}</td>
                  <td>{{ item.sensor }}</td>
                  <td >
                    <div v-if="item.assigned" class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.assigned.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.assigned.first_name }} {{ item.assigned.last_name }}</p>
                        <p class="mb-0">{{ item.assigned.email }}</p>
                      </div>
                    </div>
                  </td>
                   <td >
                    <div v-if="item.creator" class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.creator.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.creator.first_name }} {{ item.creator.last_name }}</p>
                        <p class="mb-0">{{ item.creator.email }}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <a href="javascript:;" class="me-3" @click.prevent="fetchQRcode(item.id, item.house.name, item.hid)">
                       <i class="fa fa-qrcode fs-5" aria-hidden="true"></i>
                    </a>
                    <span>
                      <!-- <router-link :to="{ name: 'houseDeviceList', params: { houseId: item.id } }" class="me-3">
                        <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                      </router-link> -->
                      <router-link 
                        :to="{ 
                          name: 'houseDeviceList', 
                          params: { houseId: item.id },
                          query: { 
                            search: listRequest.search,
                            country: listRequest.country,
                            state: listRequest.state,
                            city: listRequest.city,
                            assigned: listRequest.assigned,
                            page: listRequest.page
                          }
                        }"   
                        class="me-3">
                        <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                      </router-link>
                    </span>
                    <a href="javascript:;" @click.prevent="confirmDelete(item.id)">
                      <i class="fa fa-trash-o fs-4" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addHouse v-if="shown.addHouse" ref="addHouse" @hide="hideModal('addHouse')" @getList="getList" />
  <downloadQR v-if="shown.downloadQR" ref="downloadQR" :qrcodeImg="qrcodeImg" @hide="hideModal('downloadQR')" />
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'house'"  @update="deleteHouse" @hide="hideModal('deleteModal')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addHouse from '@/components-gc/modal/houseList/addHouse.vue';
import downloadQR from '@/components-gc/modal/downloadQR.vue'
import deleteModal from '@/components-gc/modal/delete.vue'
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';
import { getQRcode, createQRImg } from '@/methods-gc/qrCode';

export default {
  data() {
    return {
      modals: {},
      houseList: [],
      listRequest: {
        country: "",
        state: "",
        city: "",
        search: "",
        assigned: "",
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      options: {
        country: [],
        state: [],
        city: [],
        assigned: ['All', 'Assigned', 'Unassigned']
      },
      shown: {
        addHouse: false,
        downloadQR: false,
        deleteModal: false
      },
      qrcodeImg: '',
      verifyCode: '',
      houseId: ''
    };
  },
  components: {
    addHouse,
    Pagination,
    downloadQR,
    deleteModal
  },
  mounted() {
    if (this.$route.query) {
       this.listRequest.search = this.$route.query.search || '';
       this.listRequest.country = this.$route.query.country || '';
       this.listRequest.state = this.$route.query.state || '';
       this.listRequest.city = this.$route.query.city || '';
       this.listRequest.assigned = this.$route.query.assigned || '';
       this.listRequest.page = parseInt(this.$route.query.page) || 1;
     }
     
    this.getCountry();
    this.getList();
  },
  watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'house' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
          this.options.country.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
          this.options.state.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'house', country: this.listRequest.country, state: this.listRequest.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
          this.options.city.unshift('All');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
        if(params[key] !== null && typeof params[key] === 'string') {
          params[key] = params[key].toLowerCase();
        }
      });

      const api = `${process.env.VUE_APP_PATH}/house/list`;
      this.axios.post(api, params)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.houseList = res.data.data;
          this.total = res.data.total;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = '';
        this.listRequest.city = '';
        this.options.state = [];
        this.options.city = [];
        if(val && val !== 'All') {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = ''; 
        this.options.city = [];
        if(val && val !== 'All') {
          this.getCity();
        }
      }
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    async fetchQRcode(houseId, name, hid) {
      try {
        const { qrcodeImg, verifyCode } = await getQRcode(houseId);
        this.qrcodeImg = qrcodeImg;
        this.verifyCode = verifyCode;
        this.qrcodeImg = await createQRImg(qrcodeImg, name, hid, verifyCode);
        this.showModal('downloadQR');
      } catch (error) {
        console.log(error.message);
      }
    },
     clearAll() {
      const isAllCleared = !this.listRequest.country && !this.listRequest.state && !this.listRequest.city && !this.listRequest.assigned;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, country: '', state: '', city: '', assigned: '' };
        this.options.state = [];
        this.options.city = [];
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    confirmDelete(id) {
      this.houseId = id;
      this.showModal('deleteModal');
    },
    deleteHouse() {
      console.log('deleteHouse', this.houseId);
      const api = `${process.env.VUE_APP_PATH}/house/delete`;
      const payload = {
        house_id: this.houseId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('House has been deleted successfully.');
          this.hideModal('deleteModal');
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  }
};
</script>

