<template>
    <div class="card map-sidebarh">
        <div class="card-header d-flex justify-content-between align-items-center flex-wrap border-bottom-0 text-secondary">
            <div class="d-flex align-items-center gap-1">
                <router-link :to="getBackRoute()" class="link-dark">
                    <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
                </router-link>
                <p class="fs-5 mb-0">Analyze</p>
            </div>
            <p class="fs-small mb-0">Last Update: {{ sensorData.last_update_time }}</p>
        </div>
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-between gap-2 p-3" :class="{
                'text-danger': sensorData.status === 'critical',
                'text-warning': sensorData.status === 'risk',
                'txt-primary': sensorData.status === 'good'
            }">
                <div class="d-flex flex-column align-items-center flex-grow-0">
                    <ThermometerIcon size="44"/>
                    <p class="fs-sm mb-0">{{ sensorData.status }}</p>
                </div>
                <h3>{{ sensorData.value }}%</h3>
            </div>
            <div class="d-flex justify-content-between align-items-center gap-1">
                <div v-if="sensorData.battery" class="d-flex align-items-center gap-2" :class="{
                    'text-danger': sensorData.battery.status === 'critical',
                    'text-warning': sensorData.battery.status === 'risk',
                    'txt-primary': sensorData.battery.status === 'good'
                }">
                    <Battery60Icon :size="18"/>
                    <p class="mb-0">Est. {{ sensorData.battery.value }} Days Left</p>
                </div>
                <div v-if="sensorData.connection" class="d-flex align-items-center gap-2" :class="{
                    'text-danger': sensorData.connection.status === 'critical',
                    'text-warning': sensorData.connection.status === 'risk',
                    'txt-primary': sensorData.connection.status === 'good'
                }">
                    <WifiStrengthIcon :size="18"/>
                    <p class="mb-0">{{ $capitalizeFirst(sensorData.connection.status) }} Connection</p>
                </div>
            </div>
            <div id="chart">
                <apexchart ref="chart" type="bar" height="350" :options="weekChartOptions" :series="weekSeries"></apexchart>
            </div>
            <p class="text-center text-primary">Past Week</p>
            <div id="chart-two-week">
                <apexchart ref="chartTwoWeek" type="bar" height="350" :options="twoWeekChartOptions" :series="twoWeekSeries"></apexchart>
            </div>
            <p class="text-center text-primary">Past 2 Weeks</p>
            <div id="chart-month">
                <apexchart ref="chartMonth" type="bar" height="350" :options="monthChartOptions" :series="monthSeries"></apexchart>
            </div>
            <p class="text-center text-primary">Past Month</p>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            sensorData: {},
            weekSeries: [],
            twoWeekSeries: [],
            monthSeries: [],
            weekChartOptions: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                    colors: ['#0d6efd']
                },
                tooltip: {
                    enabled: false,
                }
            },
            twoWeekChartOptions: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                    colors: ['#0d6efd']
                },
                tooltip: {
                    enabled: false,
                }
            },
            monthChartOptions: {
                chart: {
                    type: 'bar',
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                    colors: ['#0d6efd']
                },
                tooltip: {
                    enabled: false,
                }
            },
        };
    },
    mounted() {
        this.getSensorData();
    },
    methods: {
        getSensorData() {
            const api = `${process.env.VUE_APP_PATH}/house/device/sensor_data`;
            this.axios.post(api, {
                sensor_id: this.$route.params.sensorId,
                type: 'tp'
            })
            .then((res) => {
                if (res.data.status_code === 'SYSTEM_1000') {
                    this.sensorData = res.data.data;
                    this.updateChart();
                } else {
                    console.log(res.data.status_code);
                }
            })
            .catch(error => {
                console.log('Error:', error);
            });
        },
        updateChart() {
            const weekData = this.sensorData.week;
            const twoWeekData = this.sensorData.two_week;
            const monthData = this.sensorData.month;
            const orderedDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            
            // Create a map of day names to their corresponding data for week
            const dayDataMap = Object.keys(weekData).reduce((acc, date) => {
                const dayName = moment(date).format('ddd');
                acc[dayName] = weekData[date];
                return acc;
            }, {});

            // Order the data according to the orderedDays array for week
            const orderedData = orderedDays.map(day => dayDataMap[day] || 0);

            this.weekSeries = [
                {
                    name: 'moisture',
                    data: orderedData
                }
            ];
            this.weekChartOptions.xaxis.categories = orderedDays;

            // Process two-week data
            const twoWeekOrderedData = Object.keys(twoWeekData).map(date => twoWeekData[date]);
            const twoWeekCategories = Object.keys(twoWeekData).map(date => moment(date).format('DD'));

            this.twoWeekSeries = [
                {
                    name: 'moisture',
                    data: twoWeekOrderedData
                }
            ];
            this.twoWeekChartOptions.xaxis.categories = twoWeekCategories;

            // Process month data
            const monthOrderedData = Object.keys(monthData).map(date => monthData[date]);
            const monthCategories = Object.keys(monthData).map(date => moment(date).format('DD'));

            this.monthSeries = [
                {
                    name: 'moisture',
                    data: monthOrderedData
                }
            ];
            this.monthChartOptions.xaxis.categories = monthCategories;

            this.$nextTick(() => {
                this.$refs.chart.updateOptions(this.weekChartOptions);
                this.$refs.chartTwoWeek.updateOptions(this.twoWeekChartOptions);
                this.$refs.chartMonth.updateOptions(this.monthChartOptions);
            });
        },
        getBackRoute() {
         switch(this.$route.name) {
            case 'userTemperature':
                return { name: 'userSensor' };
            case 'staffTemperature':
                return { name: 'staffSensor' };
            case 'houseTemperature':
                return { name: 'houseSensor' };
            default:
                console.log(this.$route.name);
            }
        },
    }
};
</script>

<style scoped>
</style>