import ShareVariantIcon from 'vue-material-design-icons/ShareVariant.vue';
import Battery60Icon from 'vue-material-design-icons/Battery60.vue';
import ThermometerIcon from 'vue-material-design-icons/Thermometer.vue';
import WaterPercentIcon from 'vue-material-design-icons/WaterPercent.vue';
import HomeOutlineIcon from 'vue-material-design-icons/HomeOutline.vue';
import EarHearingIcon from 'vue-material-design-icons/EarHearing.vue';
import WifiStrengthIcon from 'vue-material-design-icons/WifiStrength1.vue';
import WifiStrength3Icon from 'vue-material-design-icons/WifiStrength3.vue';
import ArrowUpThinIcon from 'vue-material-design-icons/ArrowUpThin.vue';
import ArrowDownThinIcon from 'vue-material-design-icons/ArrowDownThin.vue';


export default {
  install: (app) => {
    app.component('share-variant-icon', ShareVariantIcon);
    app.component('Battery60Icon', Battery60Icon);
    app.component('ThermometerIcon', ThermometerIcon);
    app.component('WaterPercentIcon', WaterPercentIcon);
    app.component('HomeOutlineIcon', HomeOutlineIcon);
    app.component('EarHearingIcon', EarHearingIcon);
    app.component('WifiStrengthIcon', WifiStrengthIcon);
    app.component('WifiStrength3Icon', WifiStrength3Icon);
    app.component('ArrowUpThinIcon', ArrowUpThinIcon);
    app.component('ArrowDownThinIcon', ArrowDownThinIcon);

  }
};