<template>
  <Breadcrumbs title="Delete Account Request"/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in requestList" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <router-link v-if="item.user_id" :to="{ name: 'userManagementViewDetail', params: { userId: item.user_id } }">{{ item.code }} {{ item.phone }}</router-link>
                    <span v-else>{{ item.code }} {{ item.phone }}</span>  
                  </td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.address }}</td>
                  <td>
                    <multiselect v-model="item.status" 
                    :options="statusOptions" 
                    :searchable="true" 
                    :allow-empty="false" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="Status"
                    @update:modelValue="(val) => selectChange(val, item)"
                    append-to-body></multiselect>
                  </td>
                  <td>{{ item.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  mounted() {
    this.getList();
  },
  data() {
    return {
      requestList: [],
      listRequest: {
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
      },
      total: 0,
      status: '',
      statusOptions: [ 'Pending', 'Completed', 'Canceled', 'Processing']
    }
  },
  components: {
    Pagination
  },
  methods: {
    selectChange(val, item) {
      if(val) {
        this.status = val;
        this.switchUserStatus(item);
      }
    },
    getList() {
      const api = `${process.env.VUE_APP_PATH}/deleted_account/list`;
      this.axios.post(api, this.listRequest)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.requestList = res.data.data;
          this.total = res.data.total;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    switchUserStatus(item) {
      const api = `${process.env.VUE_APP_PATH}/deleted_account/update_status
`;
      this.axios.post(api, {
        id: item.id,
        status: this.status.toLowerCase()
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    }
  }
}
</script>

<style scoped>
.table-responsive {
  min-height: calc(100vh - 255px);
}
</style>