<template>
  <div class="page-wrapper compact-wrapper" id="pageWrapper">

    <div class="error-wrapper">
      <div class="container"><img class="img-100" src="@/assets/images/other-images/sad.png" alt="">
        <div class="error-heading">
          <h2 class="headline font-danger">404</h2>
        </div>
        <div class="col-md-8 offset-md-2">
          <p class="sub-content">The page you are attempting to reach is currently not available. This may be because the
            page does not exist or has been moved.</p>
        </div>
        <div>
          <router-link 
            class="btn btn-danger-gradien btn-lg" 
            :to="memberInfo.role === 'nodeLogList' ? { name: 'nodeLogList' } : '/'">
            BACK TO HOME PAGE
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['memberInfo']),
  },
  created() {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon && this.memberInfo.role === 'nodeLogList') {
      document.title = 'Error404';
      favicon.parentNode.removeChild(favicon);
    }
  },
}
</script>

