<template>
  <div class="modal fade" id="editInfoModal" tabindex="-1" aria-labelledby="editInfoLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit Update Information</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label for="file" class="col-form-label fw-semibold">Upload File</label>
                <Field v-model="file" name="file" type="file" class="form-control" id="file" :validateOnBlur="false" />
                <ErrorMessage name="file" class="text-danger" />
              </div>
              <div class="col-md-12 mb-3">
                <label for="information" class="col-form-label fw-semibold">Update information</label>
                <Field name="information" as="textarea" class="form-control" placeholder="Add text" id="information" 
                v-model="localDetail.content"  />
                <ErrorMessage name="information" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';


export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      localDetail: { ...this.detail },
      file: '',
      editorOption: {
        placeholder: 'content',
      },
    };
  },
  props: ['detail'],
  emits: ['hide', 'update'],
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/update/update_file_content`;
      
      const formData = new FormData();
      formData.append('id', this.$route.params.updateId);
      formData.append('content', this.localDetail.content);
      formData.append('binfile', this.file);

      this.axios.post(api, formData)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('Updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
};
</script>