<template>
  <div class="modal fade" id="addNewModal" tabindex="-1" aria-labelledby="addNewLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New Version</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="date" class="col-form-label fw-semibold">Publish Date and Time</label>
                <Field name="date" :rules="{ required: true }" v-slot="{ field }" id="date">
                  <Datepicker1 class="datepicker-here form-control digits py-0" placeholder="Date"
                    v-model="date" v-bind="field" data-language="en" :min-date="new Date()"></Datepicker1>
                  <ErrorMessage name="date" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-6 mb-3">
                <label for="type" class="col-form-label fw-semibold">Version</label>
                <p class="mb-0 text-secondary">{{ initVersion.version }}</p>
              </div>
              <div class="col-md-12 mb-3">
                <div>
                  <label for="content" class="col-form-label fw-semibold">Content</label>
                  <quill-editor v-model:value="content" :options="editorOption" />
                </div>
              </div>
              <div class="col-md-12">
                <label for="note" class="col-form-label fw-semibold">Note</label>
                <Field v-model="note" name="note" as="textarea" class="form-control" placeholder="Add text" id="note" />
                <ErrorMessage name="note" class="text-danger" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  data() {
    return {
      date: null,
      content: '',
      note: '',
      editorOption: {
        placeholder: 'content',
      },
      initVersion: {},
    }
  },
  mounted() {
    this.getVersion();
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker1,
  },
  methods: {
    getVersion() {
      const api = `${process.env.VUE_APP_PATH}/member_criteria/create`;
      this.axios.get(api)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.initVersion = res.data.data;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/member_criteria/update`;
      const apiContent = `${process.env.VUE_APP_PATH}/member_criteria/update_content`;
      
      const payload = {
        id: this.initVersion.id,
        publish_at: this.date,
        note: this.note
      };

      const payloadContent = {
        id: this.initVersion.id,
        content: this.content
      };

      this.axios.post(api, payload)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            if (this.content) {
              this.updateContent(apiContent, payloadContent);
            } else {
              this.$emit('update');
              this.$emit('hide');
              this.$toastSuccess('Added successfully');
            }
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    updateContent(apiContent, payloadContent) {
      this.axios.post(apiContent, payloadContent)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update');
            this.$emit('hide');
            this.$toastSuccess('Updated successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    }
  },
  emits: ['update', 'hide']
}
</script>