<template>
  <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="role" class="col-form-label fw-semibold">Role</label>
                <Field v-model="localData.name" name="role" type="text" class="form-control" placeholder="Role" rules="required"/>
                <ErrorMessage name="role" class="text-danger"></ErrorMessage>
              </div>
              <div class="col-md-6 mb-3">
                <label for="password" class="col-form-label fw-semibold">Status</label>
                <div class="d-flex gap-2 align-items-center">
                      <label :class="['form-check-label', 'mb-0', { 'txt-primary': localData.status }]">{{ localData.status ? 'Enabled' : 'Disabled' }}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"  
                        v-model="localData.status" :true-value="1" :false-value="0">
                      </div>
                    </div>
              </div>
            </div>
            <p class="fs-6">Dashboard Permissions</p>
             <div class="ms-3 mb-3">
              <div class="checkbox checkbox-primary" v-for="permission in localData.permissions" :key="permission.id">
                <input :id="permission.id" :value="permission.id" type="checkbox" v-model="permission_ids"
                 :disabled="permission.name === 'Settings'" :checked="permission.enabled">
                <label :for="permission.id" :class="{ 'none' : permission.name === 'Settings' }">
                  {{ permission.name }}
                </label>
                <div v-if="permission.children.length" class="submenu-box position-relative">
                  <div class="checkbox checkbox-primary" v-for="child in permission.children" :key="child.id">
                    <input :id="child.id" type="checkbox" 
                    :value="child.id" v-model="permission_ids" :checked="permission.enabled">
                    <label :for="child.id">{{ child.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      role: '',
      itemStatus: false,
      permission_ids: [],
      localData: JSON.parse(JSON.stringify(this.data))
    };
  },
  props: ['data'],
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  mounted() {
    this.initializePermissionIds();
  },
  methods: {
    initializePermissionIds() {
      this.localData.permissions.forEach(permission => {
        if (permission.enabled) {
          this.permission_ids.push(permission.id);
        }
        permission.children.forEach(child => {
          if (child.enabled) {
            this.permission_ids.push(child.id);
          }
        });
      });
    },
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/role/update`;
      
      const payload = {
        role_id: this.localData.id,
        name: this.localData.name,
        enable: this.localData.status,
        permission_ids: this.permission_ids,
      };

      this.axios.post(api, payload)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('update');
            this.$emit('hide');
            this.$toastSuccess('Added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  emits: ['update', 'hide']
};
</script>

<style lang="scss" scoped>
.submenu-box {
  margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 17px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}

.submenu-box .checkbox::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 15px;
  vertical-align: middle;
}
</style>