<template>
  <div class="modal fade" id="editHouseModal" tabindex="-1" aria-labelledby="editHouseLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form ref="form" @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit House</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-12 col-md-6 mb-md-3">
                <label for="houseName" class="col-form-label fw-semibold">House Name</label>
                <div class="position-relative">
                  <Field name="houseName" type="text" class="form-control" placeholder="House Name" id="houseName" v-model="localHouseData.name" :rules="required" />
                  <ErrorMessage name="houseName" class="text-danger" />
                </div>
              </div>
              <div class="col-12 col-md-3 mb-md-3">
                <label for="postcode" class="col-form-label fw-semibold">Postcode</label>
                <div class="position-relative">
                  <Field name="postcode" type="text" class="form-control" placeholder="Postcode" id="postcode" v-model="localHouseData.postcode" />
                  <ErrorMessage name="postcode" class="text-danger" />
                </div>
              </div>
              <div class="col-12 col-md-3 mb-md-3">
                <label class="col-form-label fw-semibold">Country</label>
                <Field name="country" v-slot="{ field }" :rules="{ required: true }">
                  <multiselect v-model="localHouseData.country" 
                  :options="options.country" 
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  placeholder="Country" 
                  :allow-empty="false" 
                  v-bind="field" 
                   :max-height="290" 
                  @update:modelValue="selectChange('country')"></multiselect>
                  <ErrorMessage name="country" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-12 col-md-3">
                 <label class="col-form-label fw-semibold">State</label>
                  <Field name="state" v-slot="{ field }" :rules="{ required: true }">
                    <multiselect v-model="localHouseData.state" 
                    :options="options.state" 
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="State" 
                    :allow-empty="false" 
                    v-bind="field" 
                     :max-height="200" 
                    @update:modelValue="selectChange('state')"></multiselect>
                    <ErrorMessage name="state" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-12 col-md-3">
                <label class="col-form-label fw-semibold">City</label>
                  <Field name="city" v-slot="{ field }" :rules="{ required: true }">
                    <multiselect v-model="localHouseData.city" 
                    :options="options.city" 
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    placeholder="City" 
                    :allow-empty="false" 
                    v-bind="field" 
                     :max-height="200" 
                    @update:modelValue="selectChange('city')"></multiselect>
                    <ErrorMessage name="city" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-12 col-md-6">
                <label for="streetAddress" class="col-form-label fw-semibold">Street Address</label>
                  <div class="position-relative">
                    <Field name="streetAddress" type="text" class="form-control" placeholder="Street Address" id="streetAddress" v-model="localHouseData.address" />
                    <ErrorMessage name="streetAddress" class="text-danger" />
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-end">
              <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      localHouseData: { ...this.houseData },
      options: {
          country: [],
          state: [],
          city: []
        },
    }
  },
  props: ['houseData'],
  emits: ['update', 'hide'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {
    this.getCountry();
    if(this.localHouseData.country) {
      this.getState();
    }
    if(this.localHouseData.state) {
      this.getCity();
    }
    this.$nextTick(() => {
      this.$refs.form.setFieldValue('country', this.localHouseData.country);
      this.$refs.form.setFieldValue('state', this.localHouseData.state);
      this.$refs.form.setFieldValue('city', this.localHouseData.city);
    });
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'global' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'global', country: this.localHouseData.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'global', country: this.localHouseData.country, state: this.localHouseData.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(field) {
      if(field === 'country') {
        this.localHouseData.state = '';
        this.localHouseData.city = '';
        this.options.state = [];
        this.options.city = [];
        this.getState();
      } else if(field === 'state') {
        this.localHouseData.city = '';
        this.options.city = [];
        this.getCity();
      }
    },
    async submitForm() {
      const isValid = await this.$refs.form.validate();
      const api = `${process.env.VUE_APP_PATH}/house/update`;
      const payload = {
        house_id: this.localHouseData.id,
        name: this.localHouseData.name,
        postcode: this.localHouseData.postcode,
        country: this.localHouseData.country,
        city: this.localHouseData.city,
        state: this.localHouseData.state,
        address: this.localHouseData.address
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$emit('hide');
          this.$toastSuccess('House profile updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    // deleteHouse() {
    //   const api = `${process.env.VUE_APP_PATH}/house/delete`;
    //   const payload = {
    //     house_id: this.localHouseData.id
    //   };

    //   this.axios.post(api, payload)
    //   .then((res) => {
    //     if (res.data.status_code === 'SYSTEM_1000') {
    //       this.$toastSuccess('House deleted successfully. You will be redirected to the house list.');
    //       setTimeout(() => {
    //         this.$emit('hide');
    //         this.$emit('update');
    //       }, 4000);
    //     } else {
    //       const msg = checkStatusCode(res.data.status_code);
    //       this.$toastError(msg);
    //     }
    //   }).catch(error => {
    //     console.log('Error:', error);
    //   });
    // },
  },
  
}
</script>

<style scoped>
.modal-body {
  min-height: 380px;
}
</style>