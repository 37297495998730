<template>
  <Breadcrumbs title="Node Log List" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
       <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="date-select-wrap flex-shrink-0">
              <Datepicker1 class="datepicker-here form-control digits py-0" position="left" placeholder="Created at"
                     v-model="date" data-language="en" :enable-time-picker="false" range></Datepicker1>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
          </div>
          <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
            <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
            <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Nid</th>
                  <th scope="col">Temperature</th>
                  <th scope="col">Humidity</th>
                  <th scope="col">Moisture</th> 
                  <th scope="col">Leakage</th> 
                  <th scope="col">Battery Voltage</th> 
                  <th scope="col">RSSI</th>
                  <th scope="col">Audio Upload</th>
                  <th scope="col">Message</th>
                  <th scope="col">Created at</th>
                  <th scope="col">File</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>{{ item.nid }}</td>
                  <td>{{ item.tp }}</td>
                  <td>{{ item.hm }}</td>
                  <td>{{ item.mo }}</td>
                  <td>{{ item.lk }}</td>
                  <td>{{ item.bt }}</td>
                  <td>{{ item.rs }}</td>
                  <td>{{ item.au }}</td>
                  <td>{{ item.msg }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>
                    <div v-if="item.file" class="custom-audio" @click="showAudioModal(item.file)">
                      <div class="triangle"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <AudioModal v-if="shownModal" ref="audioModal" :audioFile="audioFile" @hide="hideAudioModal"/>
</template>

<script>
import { mapState } from 'vuex';
import Modal from "bootstrap/js/dist/modal";
import AudioModal from "@/components-gc/modal/audio";
import Pagination from '@/components-gc/Pagination.vue';
import Datepicker from "vue3-datepicker";
import Datepicker1 from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      list: [],
      listRequest: {
        search: "",
        start_date: "",
        end_date: "",
        page: 1,
        limit: 10,
        order_by: "",
        order: "desc",
      },
      total: 0,
      date: '',
      shownModal: false,
      audioModal: null 
    };
  },
  components: {
    Pagination,
    Datepicker,
    Datepicker1,
    AudioModal,
  },
  computed: {
    ...mapState('user', ['memberInfo']),
  },
  mounted() {
    // console.log('title', document.title, this.memberInfo.role)
    // if (this.memberInfo.role === 'nodeLogList') {
    //   document.title = 'Node Log List';
    //   console.log('title2', document.title)
    //   const favicon = document.querySelector('link[rel="icon"]');
    //   if (favicon) {
    //     favicon.parentNode.removeChild(favicon);
    //   }
    // }

    this.getList();
  },
  methods: {
    getList() {
      const api = `${process.env.VUE_APP_PATH}/node_log/list`;
      this.axios.post(api, this.listRequest)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    handleSearch() {
      let newStartDate = '';
      let newEndDate = '';
      
      if(this.date && this.date.length > 0) {
        newStartDate = this.date[0] ? this.date[0].toISOString().split('T')[0] : '';
        newEndDate = this.date[1] ? this.date[1].toISOString().split('T')[0] : '';
      }
      
      if (this.listRequest.start_date === newStartDate && this.listRequest.end_date === newEndDate) {
        return; 
      }

      this.listRequest.start_date = newStartDate;
      this.listRequest.end_date = newEndDate;
      this.listRequest.page = 1;
      this.getList();
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showAudioModal(file) {
         this.audioFile = file;
         this.shownModal = true;
         this.$nextTick(() => {
            this.audioModal = new Modal(this.$refs.audioModal.$el);
            this.audioModal.show();
            this.$refs.audioModal.$el.addEventListener('hidden.bs.modal', this.onHidden);
         });
      },
      hideModal() {
         this.audioModal.hide();
      },
      onHidden() {
         this.shownModal = false;
         if(this.$refs.audioModal) {
            this.$refs.audioModal.$el.removeEventListener('hidden.bs.modal', this.onHidden);
         }
      },
  },
  watch: {
    'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    },
    'memberInfo.role': {
      handler(newRole, oldRole) {
        if (newRole === 'nodeLogList') {
          document.title = 'Node Log List';
          const favicon = document.querySelector('link[rel="icon"]');
          if (favicon) {
          favicon.parentNode.removeChild(favicon);
          }
        }
      },
      immediate: true
    }
  },
};
</script>

<style scoped lang="scss">
.date-select-wrap {
  min-width: 290px;
}
</style>
