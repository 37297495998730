<template>
   <div class="card map-sidebarh">
      <div class="card-header d-flex justify-content-between align-items-center flex-wrap border-bottom-0 text-secondary">
          <div class="d-flex align-items-center gap-1">
          <router-link :to="getBackRoute()" class="link-dark">
              <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
          </router-link>
          <p class="fs-5 mb-0">Analyze</p>
          </div>
          <p class="fs-small mb-0">Last Update: {{ audioData.last_update_time }}</p>
      </div>
      <div class="card-body">
          <div class="d-flex align-items-center justify-content-between gap-2 p-3" :class="{
              'text-danger': audioData.status === 'critical',
              'text-warning': audioData.status === 'risk',
              'txt-primary': audioData.status === 'good',
          }">
              <div class="d-flex flex-column align-items-center flex-grow-0">
                 <EarHearingIcon size="44"/>
                <p class="fs-sm mb-0">{{ $capitalizeFirst(audioData.status) }}</p>
              </div>
              <div>
                 <h3>{{ audioData.total }}</h3>
               <h5 class="mb-0">Files</h5>
              </div>
            </div>
          <div class="d-flex justify-content-between align-items-center gap-1">
              <div v-if="audioData.battery" class="d-flex align-items-center gap-2" :class="{
                    'text-danger': audioData.battery.status === 'critical',
                    'text-warning': audioData.battery.status === 'risk',
                    'txt-primary': audioData.battery.status === 'good'
                }">
                    <Battery60Icon :size="18"/>
                    <p class="mb-0">Est. {{ audioData.battery.value }} Days Left</p>
                </div>
              <div v-if="audioData.connection" class="d-flex align-items-center gap-2" :class="{
                    'text-danger': audioData.connection.status === 'critical',
                    'text-warning': audioData.connection.status === 'risk',
                    'txt-primary': audioData.connection.status === 'good'
                }">
                    <WifiStrengthIcon :size="18"/>
                    <p class="mb-0">{{ $capitalizeFirst(audioData.connection.status) }} Connection</p>
                </div>
          </div>     
      </div>
      <div v-for="audio in audioData.audio_list" :key="audio.file" class="card-body text-secondary d-flex align-items-center justify-content-between gap-2 border-bottom">
         <EarHearingIcon size="32" :class="{
                    'text-danger': audio.status === 'critical',
                    'text-warning': audio.status === 'risk',
                    'txt-primary': audio.status === 'good'
                }"/>
         <div>
            <span class="mb-0 me-1">{{ audio.description }}</span>
            <small class="">{{ audio.created_at }}</small>
         </div>
         <div>
            <div class="custom-audio" @click="showAudioModal(audio.file)">
               <div class="triangle"></div>
            </div>
            <p class="fs-sm text-nowrap">{{ audio.length }}</p>
         </div>
      </div> 
   </div>
   <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
   <AudioModal v-if="shownModal" ref="audioModal" :audioFile="audioFile" @hide="hideAudioModal"/>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import AudioModal from "@/components-gc/modal/audio";
import Pagination from '@/components-gc/Pagination.vue';

export default {
   data() {
      return {
         audioData: {},
         listRequest: {
            "sensor_id" : this.$route.params.sensorId,
            "page": 1,
            "limit": 10
         },
         total: null,
         audioFile: '',
         shownModal: false,
         audioModal: null 
      }
   },
   components: { 
      AudioModal,
      Pagination
  },
  mounted() {
     this.getAudioData();
   },
   methods: {
      getAudioData() {
            const api = `${process.env.VUE_APP_PATH}/house/device/sensor_audio`;
            this.axios.post(api, this.listRequest)
            .then((res) => {
                if (res.data.status_code === 'SYSTEM_1000') {
                    this.audioData = res.data.data;
                    this.total = res.data.data.total;
                } else {
                    console.log(res.data.status_code);
                }
            })
            .catch(error => {
                console.log('Error:', error);
            });
        },
        updatePage(page) {
            this.listRequest.page = page;
            this.getAudioData();
         },
      showAudioModal(file) {
         this.audioFile = file; // Store the file URL
         this.shownModal = true;
         this.$nextTick(() => {
            this.audioModal = new Modal(this.$refs.audioModal.$el);
            this.audioModal.show();
            this.$refs.audioModal.$el.addEventListener('hidden.bs.modal', this.onHidden);
         });
      },
      hideModal() {
         this.audioModal.hide();
      },
      onHidden() {
         this.shownModal = false;
         if(this.$refs.audioModal) {
            this.$refs.audioModal.$el.removeEventListener('hidden.bs.modal', this.onHidden);
         }
      },
      getBackRoute() {
         switch(this.$route.name) {
            case 'userFiles':
               return { name: 'userSensor' };
            case 'staffFiles':
               return { name: 'staffSensor' };
            case 'houseFiles':
               return { name: 'houseSensor' };
            default:
               console.log(this.$route.name);
         }
      },
  }
}
</script>