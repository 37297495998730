<template>
  <div class="card map-sidebarh" >
    <div class="card-header d-flex justify-content-between border-bottom-0 text-secondary">
      <p class="fs-5 mb-0">Device List</p>
      <p class="mb-0">{{ deviceList.length }} Device</p>
    </div>
    <div v-for="device in deviceList" :key="device.gateway.id" class="card-body">
     <div class="d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
        <div class="d-flex gap-2 txt-primary align-items-center">
          <div>
            <vue-feather type="share-2" fill="#14a1b1"></vue-feather>
          </div>
          <div>
            <h6 class="mb-0">{{ device.gateway.name }}．Group {{ device.gateway.group_id }}</h6>
            <p class="mb-0 text-secondary">{{ device.gateway.gid }}</p>
          </div>
        </div>
        <div>
          <a href="javascript:;"  @click.prevent="showModal('editDeviceModal', device.gateway)">
            <vue-feather type="edit" size="18" stroke="#08b258" class="me-2"></vue-feather>
          </a>
          <router-link :to="getRoute('gateway', device.gateway.id)">
            <i class="fa fa-sliders txt-primary fs-5" aria-hidden="true"></i>
          </router-link>
        </div>
      </div>
      <div v-for="(sensor, index) in device.sensors.slice(0, showAllSensors[device.gateway.id] ? device.sensors.length : 2)" :key="sensor.id" :class="[index !== 0 ? 'border-top mt-3 pt-3' : '']">
        <div class="d-flex align-items-center justify-content-between gap-3 flex-wrap">
          <div>
            <h6 class="mb-0 text-secondary">{{ sensor.name }}</h6>
            <p class="mb-0 text-secondary">{{ sensor.nid }}</p>
            <div class="d-flex flex-row align-items-center gap-1">
              <Battery60Icon size="20" :class="statusClass(sensor.battery_status)"/>
              <WifiStrength3Icon size="20" :class="statusClass(sensor.connect_status)"/>
              <ThermometerIcon size="20" :class="statusClass(sensor.temperature_status)"/>
              <WaterPercentIcon size="20" :class="statusClass(sensor.moisture_status)"/>
              <HomeOutlineIcon size="20" :class="statusClass(sensor.status)"/>
              <p class="mb-0 ms-2" :class="statusClass(sensor.status)">
                {{ statusText(sensor.status) }}
              </p>
            </div>
          </div>
          <h5 class="mb-0">
            <router-link :to="getRoute('sensor', sensor.id)" class="badge bg-light align-self-start" :class="statusClass(sensor.status)">Detail</router-link>
          </h5>
        </div>
      </div>
      <div v-if="device.sensors.length > 2" class="text-center mt-">
        <a href="javascript:;" class="text-secondary" @click.prevent="toggleShowMore(device.gateway.id)">
          {{ showAllSensors[device.gateway.id] ? 'Less Detail' : 'More Detail' }} ({{ device.sensors.length }})
          <i :class="['fa', showAllSensors[device.gateway.id] ? 'fa-chevron-up' : 'fa-chevron-down', 'ms-2']" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
  <editDeviceModal v-if="shown.editDeviceModal" ref="editDeviceModal" :type="'gateway'" :data="deviceGateway"
    @update="getDeviceList" @hide="hideModal('editDeviceModal')"/>
</template>

<script>
import { checkStatusCode } from '@/methods-gc/statusCode';
import Modal from "bootstrap/js/dist/modal";
import editDeviceModal from '@/components-gc/modal/editDevice.vue'
import { transformMixin } from '@/methods-gc/transform'; 

export default {
  mixins: [transformMixin], 
  data() {
    return {
      deviceList: [],
      showAllSensors: {},
      modals: {},
      shown: {
        editDeviceModal: false,
      },
      deviceGateway: null,
    }
  },
  components: {
    editDeviceModal
  },
  mounted() {
    this.getDeviceList();
  },
  methods: {
    getDeviceList() {
      const api = `${process.env.VUE_APP_PATH}/house/device/list`;
      this.axios.post(api, {
        house_id: this.$route.params.houseId
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.deviceList = res.data.devices;
          this.deviceList.forEach(device => {
            this.showAllSensors[device.gateway.id] = false;
          });
        } else {
          const msg = checkStatusCode(res.data.status_code);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    toggleShowMore(gatewayId) {
      this.showAllSensors[gatewayId] = !this.showAllSensors[gatewayId];
    },
    getRoute(type, id) {
      switch(this.$route.name) {
        case 'userDeviceList':
          if(type === 'gateway') {
            return { name: 'userGateway', params: { gatewayId: id } };
          } else if(type === 'sensor') {
            return { name: 'userSensor', params: { sensorId: id } };
          }
        case 'staffDeviceList':
          if(type === 'gateway') {
            return { name: 'staffGateway', params: { gatewayId: id } };
          } else if(type === 'sensor') {
            return { name: 'staffSensor', params: { sensorId: id } };
          }
        case 'houseDeviceList':
          if(type === 'gateway') {
            return { name: 'houseGateway', params: { gatewayId: id } };
          } else if(type === 'sensor') {
            return { name: 'houseSensor', params: { sensorId: id } };
          }
        default:
          console.log(this.$route.name);
      }
    },
    showModal(name, deviceGateway = null) {
      if(name === 'editDeviceModal') {
        this.deviceGateway = deviceGateway;
      }
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
  }
}
</script>