<template>
  <div class="modal fade" id="addHouseModal" tabindex="-1" aria-labelledby="addHouseLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New House</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="houseName" class="col-form-label fw-semibold">House Name</label>
                <div class="position-relative">
                  <Field name="houseName" type="text" class="form-control" placeholder="House Name" id="houseName" rules="required" value="" />
                  <ErrorMessage name="houseName" class="text-danger" />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="postcode" class="col-form-label fw-semibold">Postcode</label>
                <div class="position-relative">
                  <Field name="postcode" type="text" class="form-control" placeholder="Postcode" id="postcode" value=""/>
                  <ErrorMessage name="postcode" class="text-danger" />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label class="col-form-label fw-semibold">Country</label>
                <Field name="country" v-slot="{ field }" :rules="{ required: true }" >
                  <multiselect v-model="listRequest.country" 
                    :options="options.country" 
                    searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :allow-empty="false"
                    placeholder="Country" 
                    v-bind="field" 
                    :max-height="290" 
                    @update:modelValue="val => selectChange(val, 'country')">
                  </multiselect>
                  <ErrorMessage name="country" class="text-danger"></ErrorMessage>
                </Field>
              </div>
              <div class="col-md-3">
                 <label class="col-form-label fw-semibold">State</label>
                  <Field name="state" v-slot="{ field }" :rules="{ required: true }">
                    <multiselect v-model="listRequest.state" 
                      :options="options.state" 
                      :searchable="true" 
                      :close-on-select="true" 
                      :show-labels="false" 
                      :allow-empty="false"
                      placeholder="State" 
                      v-bind="field" 
                      :max-height="200" 
                      @update:modelValue="val => selectChange(val, 'state')">
                    </multiselect>
                    <ErrorMessage name="state" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-md-3">
                <label class="col-form-label fw-semibold">City</label>
                  <Field name="city" v-slot="{ field }" :rules="{ required: true }">
                    <multiselect v-model="listRequest.city" 
                      :options="options.city" 
                      :searchable="true" 
                      :close-on-select="true" 
                      :show-labels="false" 
                      :allow-empty="false"
                      placeholder="City" 
                      v-bind="field" 
                      :max-height="200" 
                      @update:modelValue="val => selectChange(val, 'city')">
                    </multiselect>
                    <ErrorMessage name="city" class="text-danger"></ErrorMessage>
                  </Field>
              </div>
              <div class="col-md-6">
                <label for="streetAddress" class="col-form-label fw-semibold">Street Address</label>
                  <div class="position-relative">
                    <Field name="streetAddress" type="text" class="form-control" placeholder="Street Address" id="streetAddress" value=""/>
                    <ErrorMessage name="streetAddress" class="text-danger" />
                  </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '../../../methods-gc/statusCode';

export default {
  data() {
    return {
      options: {
          country: [],
          state: [],
          city: [],
        },
      listRequest: {
        country: '',
        state: '',
        city: ''
      },
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    getCountry() {
      const api = `${process.env.VUE_APP_PATH}/global/countries`;
      this.axios.get(api, {
        params: { type: 'global' }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.country = res.data.countries;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getState() {
      const api = `${process.env.VUE_APP_PATH}/global/states`;
      this.axios.get(api, {
        params: { type: 'global', country: this.listRequest.country }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.state = res.data.states;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    getCity() {
      const api = `${process.env.VUE_APP_PATH}/global/cities`;
      this.axios.get(api, {
        params: { type: 'global', country: this.listRequest.country, state: this.listRequest.state }
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.city = res.data.cities;
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    selectChange(val, field) {
      if(field === 'country') {
        this.listRequest.state = null;
        this.listRequest.city = null;
        this.options.state = [];
        this.options.city = [];
        if(val) {
          this.getState();
        }
      } else if(field === 'state') {
        this.listRequest.city = null; 
        this.options.city = [];
        if(val) {
          this.getCity();
        }
      }
    },
    submitForm(values) {
      const api = `${process.env.VUE_APP_PATH}/house/create`;
      const formData = new FormData();
      
      formData.append('name', values.houseName);
      formData.append('postcode', values.postcode);
      formData.append('country', this.listRequest.country);
      formData.append('state', this.listRequest.state);
      formData.append('city', this.listRequest.city);
      formData.append('address', values.streetAddress);

      this.axios.post(api, formData)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('getList');
            this.$emit('hide');
            this.$toastSuccess('House added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  emits: ['hide', 'getList'],
}
</script>

<style scoped>
.modal-body {
  min-height: 380px;
}
</style>
