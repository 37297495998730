<template>
  <Breadcrumbs title="Notifications Detail" main="Notifications"/>
  <div class="container-fluid">
    <div class="d-flex align-items-center gap-1 mb-5">
      <router-link :to="{ name: 'notificationsIndex', query: this.$route.query }" class="link-dark">
        <vue-feather stroke-width="3" type="arrow-left"></vue-feather>
      </router-link>
      <h5 class="mb-0">Notifications</h5>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <p class="text-secondary fs-6 mb-0"># {{ notificationDetail.id }}</p>
              <div class="fw-semibold" v-if="!isPublishDatePassed">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('editContent', 'Edit')">Edit</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div v-if="notificationDetail.editor" class="d-flex align-items-center gap-2">
                  <img class="img-50 rounded-circle" :src="notificationDetail.editor.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                  <div>
                    <h6>{{ notificationDetail.editor.first_name }} {{ notificationDetail.editor.last_name }}</h6>
                    <p class="mb-0 text-secondary">{{ notificationDetail.editor.email }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <h6>Type</h6>
                <p class="mb-0 text-secondary">{{ notificationDetail.type }}</p>
              </div>
              <div class="col-md-5">
                <h6>Created at</h6>
                <p class="mb-0 text-secondary">{{ notificationDetail.created_at }}</p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-4">
              <p class="fs-6 mb-0">Title</p>
              <h5>{{ notificationDetail.title }}</h5>
            </div>
            <div v-if="notificationDetail.content">
              <p class="fs-6 mb-0">Contents</p>
              <p class="text-secondary mb-0">{{ notificationDetail.content }}</p>
            </div>
            <div class="text-center py-4" v-if="!isPublishDatePassed && !notificationDetail.content">
              <button type="button" class="btn btn-primary" @click="showModal('editContent', 'Add')">Add Content and Setting</button>
            </div>
          </div>
        </div>
        <!-- <button v-if="!isPublishDatePassed" type="button" class="btn btn-secondary mb-4" @click.prevent="showModal('deleteModal')">Delete</button> -->
      </div>

      <div class="col-md-6">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="text-secondary mb-4">Notifications Setting</h5>
            <div v-if="notificationDetail.publish_at" class="row">
              <div class="col-md-6">
                <h6>Publish Date & Time</h6>
                <p class="mb-0 text-secondary fs-sm">{{ notificationDetail.publish_at }}</p>
              </div>
              <div class="col-md-6">
                <h6>Link URL</h6>
                <a :href="notificationDetail.url" class="fs-sm">{{ notificationDetail.url }}</a>
              </div>
            </div>
            <div v-else class="text-center py-4">
              <p class="text-secondary mb-0">Please Add Content and Setting First</p>
            </div>
          </div>
        </div>

        <div v-if="['specify', 'staff'].includes(notificationDetail.type)" class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h5 class="text-secondary mb-0">Specify Setting</h5>
              <div class="fw-semibold" v-if="!isPublishDatePassed && notificationDetail.notify_users && notificationDetail.notify_users.length > 0">
                <vue-feather type="edit" size="18" class="me-2 text-primary"></vue-feather>
                <a href="javascript:;" class="fs-6 text-primary" @click="showModal('setting')">Edit</a>
              </div>
            </div>
            <div v-if="notificationDetail.notify_users && notificationDetail.notify_users.length > 0" class="table-responsive">
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th scope="col">Index</th>
                    <th scope="col">Account</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">City</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in notificationDetail.notify_users" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.profile || 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                        <p class="mb-0">{{ item.mail_address }}</p>
                      </div>
                    </td>
                    <td>{{ item.country }}</td>
                    <td>{{ item.state }}</td>
                    <td>{{ item.city }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center py-4" v-if="!isPublishDatePassed && notificationDetail.notify_users && notificationDetail.notify_users.length === 0">
              <button type="button" class="btn btn-primary" @click="showModal('setting')">Setting</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editContent ref="editContent" v-if="shown.editContent" :notificationDetail="notificationDetail"
               :title="contentTitle" @hide="hideModal('editContent')" @update="getNotificationDetail"></editContent>
  <setting ref="setting" v-if="shown.setting" :detail="notificationDetail" @hide="hideModal('setting')"
  @update="getNotificationDetail"></setting>
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" 
  :type="'notification'"  @update="handleDelete" @hide="hideModal('deleteModal')"/>
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import editContent from '@/components-gc/modal/notifications/editContent.vue'
import setting from '@/components-gc/modal/notifications/setting.vue'
import deleteModal from '@/components-gc/modal/delete.vue'
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      notificationDetail: {},
      shown: {
        editContent: false,
        setting: false,
        deleteModal: false
      },
      modals: {},
      contentTitle: null
    }
  },
  components: { 
    editContent,
    setting,
    deleteModal
  },
  mounted() {
    this.getNotificationDetail();
  },
  methods: {
    showModal(name , title=null) {
      if(title) {
        this.contentTitle = title;
      }
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      if(this.$refs[name]) {
        this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
      }
    },
    getNotificationDetail() {
      const api = `${process.env.VUE_APP_PATH}/notifactions/detail`;
      this.axios.post(api, { id: this.$route.params.notificationsId })
        .then((res) => {
          const msg = checkStatusCode(res.data.status_code);
          if (res.data.status_code === 'SYSTEM_1000') {
            this.notificationDetail = res.data.detail;
          } else if(res.data.status_code === 'SYSTEM_0010') {
            this.$toastWarning(`${msg} You will be redirected to the list.`);
              setTimeout(() => {
                this.$router.push({ name: 'notificationsIndex' });
              }, 4000);
          } 
          else {
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    // handleDelete() {
    //   this.hideModal('deleteModal');

    //   const api = `${process.env.VUE_APP_PATH}/notifactions/delete`;
    //   const payload = {
    //     id: this.$route.params.notificationsId
    //   };

    //   this.axios.post(api, payload)
    //   .then((res) => {
    //     if (res.data.status_code === 'SYSTEM_1000') {
    //       this.$toastSuccess('Notification has been deleted successfully');
    //       this.getNotificationDetail();
    //     } else {
    //       const msg = checkStatusCode(res.data.status_code);
    //       this.$toastError(msg);
    //     }
    //   }).catch(error => {
    //     console.log('Error:', error);
    //   });
    // },
  },
  computed: {
    isPublishDatePassed() {
      const publishDate = new Date(this.notificationDetail.publish_at);
      const currentDate = new Date(new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Chicago'
      }).format(new Date()));
      
      return publishDate <= currentDate;
    }
  }
}
</script>

<style scoped>
</style>