<template>
  <div class="modal fade" id="resetPasswordModal" tabindex="-1" aria-labelledby="resetPasswordLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Reset Password</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <label for="newPassword" class="col-form-label">New Password</label>
              <div class="position-relative">
                <Field name="newPassword" type="password" class="form-control pe-5" placeholder="New Password" id="newPassword" rules="required" v-model="formData.new_password" />
                <ErrorMessage name="newPassword" class="text-danger" />
              </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '../../../methods-gc/statusCode';

export default {
  data() {
    return {
      formData: {
        user_id: null,
        new_password: ''
      }
    }
  },
  props: ['userId'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/user/change-password`;

      this.axios.post(api, {
        user_id: this.userId,
        new_password: this.formData.new_password
      })
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('hide');
          this.$toastSuccess('Password updated successfully');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  emits: ['hide']
}
</script>