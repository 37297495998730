<template>
  <div class="modal fade" id="logout" tabindex="-1" aria-labelledby="logoutLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="logoutLabel">Log out</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to log out?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" @click="logout">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { checkStatusCode } from '../../../methods-gc/statusCode';

  export default {
    emits: ['logout'],
    methods: {
          ...mapMutations('user', ['setToken']),
    logout() {
      const api = `${process.env.VUE_APP_PATH}/member/logout`;
      this.axios.get(api)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.setToken(null);
          // this.$router.replace('/auth/login');
          this.$emit('logout');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    }
    }
  }
</script>