<template>
  <div class="modal fade" id="editDeviceModal" tabindex="-1" aria-labelledby="resetPasswordLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Edit {{ type ==='gateway' ? 'Device' : 'Sensor' }} Name</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <label for="seriesNumber" class="col-form-label">{{ type ==='gateway' ? 'Device' : 'Sensor' }} Name</label>
              <div class="position-relative">
                <Field name="name" type="text" class="form-control pe-5" placeholder="Name" 
                 rules="required" v-model="deviceName" />
                <ErrorMessage name="name" class="text-danger" />
              </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      deviceName: this.data.name,
    }
  },
  props: ['type', 'data'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submitForm() {
      const api = `${process.env.VUE_APP_PATH}/house/device/${this.type}/rename`;

      const requestData = this.type === 'gateway' ? 
        { gateway_id: this.data.id, name: this.deviceName } : 
        { sensor_id: this.$route.params.sensorId, name: this.deviceName };

      this.axios.post(api, requestData)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$emit('update');
          this.$toastSuccess('Updated successfully');
          this.$emit('hide');
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
  emits: ['hide', 'update']
}
</script>