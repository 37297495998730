<template>
  <div class="modal fade" id="addStaffModal" tabindex="-1" aria-labelledby="addStaffLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <Form @submit="submitForm">
          <div class="modal-header">
            <h1 class="modal-title fs-5">Add New Staff</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
                <div class="col-12 col-md-3 mb-md-3">
                    <label class="col-form-label fw-semibold">Profile Image</label>
                    <img class="img-fluid w-100" :src="profilePreview || require('@/assets/images_gc/staff_cover.jpg')" alt="profile">
                      <!-- <input class="upload-img" type="file" name="img" @change="(e) => { readURL(e, userData) }"> -->
                </div>
                <div class="col-12 col-md-9 mb-md-3">
                  <div class="row g-3">
                    <div class="col-12 col-md-6 mb-md-5">
                      <label for="firstName" class="col-form-label fw-semibold">First Name</label>
                      <div class="position-relative">
                        <Field name="firstName" type="text" class="form-control" placeholder="First Name" id="firstName" value=""/>
                        <ErrorMessage name="firstName" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-md-5">
                      <label for="lastName" class="col-form-label fw-semibold">Last Name</label>
                      <div class="position-relative">
                        <Field name="lastName" type="text" class="form-control" placeholder="Last Name" id="lastName" value="" />
                        <ErrorMessage name="lastName" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-md-3">
                      <label class="col-form-label fw-semibold">Account</label>
                      <div class="position-relative">
                        <Field name="account" type="email" class="form-control" placeholder="Email" id="account" rules="required|email" value="" />
                        <ErrorMessage name="account" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-md-3">
                      <label class="col-form-label fw-semibold">Password</label>
                      <div class="position-relative">
                        <Field name="password" type="password" class="form-control" placeholder="Password" id="password" rules="required" value="" />
                        <ErrorMessage name="password" class="text-danger" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-3 mb-md-3">
                  <label for="mobile" class="col-form-label fw-semibold">Mobile</label>
                  <div class="position-relative">
                    <Field name="mobile" type="tel" class="form-control" placeholder="mobile" id="mobile" value="" />
                    <ErrorMessage name="mobile" class="text-danger" />
                  </div>
                </div>
                <div class="col-12 col-md-9 mb-md-3">
                  <div class="row g-3">
                    <div class="col-12 col-md-6 mb-md-3">
                      <label for="phone" class="col-form-label fw-semibold">Local Phone</label>
                      <div class="position-relative">
                        <Field name="phone" type="tel" class="form-control" placeholder="Local Phone" id="phone" value="" />
                        <ErrorMessage name="phone" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 mb-md-3">
                      <label for="profileImage" class="col-form-label fw-semibold">Upload Photo</label>
                      <div>
                        <input type="file" @change="handleFileChange" class="form-control" id="profileImage">
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Create</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { checkStatusCode } from '../../../methods-gc/statusCode';

export default {
  data() {
    return {
      profileFile: null,
      profilePreview: null,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.profileFile = file;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.profilePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.profileFile = null;
        this.profilePreview = null;
      }
    },
    submitForm(values) {
      const api = `${process.env.VUE_APP_PATH}/staff/create`;
      const formData = new FormData();
      
      formData.append('first_name', values.firstName);
      formData.append('last_name', values.lastName);
      formData.append('home_phone', values.phone);
      formData.append('phone', values.mobile);
      formData.append('email', values.account);
      formData.append('password', values.password);
      
       if (this.profileFile) {
        formData.append('profile', this.profileFile);
      }

      this.axios.post(api, formData)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.$emit('getList');
            this.$emit('hide');
            this.$toastSuccess('Staff added successfully');
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
  },
  emits: ['hide', 'getList']
}
</script>

<style>
.upload-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 93px;
    opacity: 0;
}
</style>