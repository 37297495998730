<template>
  <Breadcrumbs title="Administrator" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
       <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center 
        justify-content-between mb-3 gap-3">
          <div class="d-flex gap-3 flex-column flex-md-row flex-shrink-0">
            <div class="select-wrap flex-shrink-0 role-wrap">
              <multiselect 
                v-model="selected.role"
                :options="options.roles" 
                :searchable="true" 
                @update:modelValue="(val) => selectChange('role', val)"
                :close-on-select="true" 
                :show-labels="false" 
                placeholder="Role" 
                id="role"
                track-by="id"
                label="name"
              >
              </multiselect>
            </div>
            <div class="select-wrap flex-shrink-0">
              <multiselect v-model="selected.status" 
              :options="options.status" 
              :searchable="true"
              :close-on-select="true" 
              :show-labels="false" 
              placeholder="Status" 
              id="status"
              @update:modelValue="(val) => selectChange('status', val)">
              </multiselect>
            </div>
            <button type="button" class="btn btn-primary" @click.prevent="handleSearch">Search</button>
            <a href="javascript:void(0)" class="align-self-center clear-btn" @click.prevent="clearAll">Clear all</a>          </div>
          <div class="d-flex gap-3">
            <div class="position-relative align-self-md-center search-box flex-grow-1 flex-md-grow-0">
              <input type="text" class="form-control" placeholder="Search" v-model="listRequest.search">
              <vue-feather type="search" size="20" class="position-absolute"></vue-feather>
            </div>
            <button type="button" class="btn btn-primary text-nowrap" @click.prevent="showModal('addNew')">+ Add New</button>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Account ID</th>
                  <th scope="col">Account</th>
                  <th scope="col">Role</th>
                  <th scope="col" @click="sortList('status')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Status</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('status') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" @click="sortList('created_at')" class="cursor-p">
                    <div class="d-flex justify-content-between align-items-center gap-2">
                      <p class="mb-0">Created at</p>
                      <div>
                        <i class="fa fa-long-arrow-up sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'asc' }"></i>
                        <i class="fa fa-long-arrow-down sort-arrow" :class="{ active: isActiveSort('created_at') && listRequest.order === 'desc' }"></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in list" :key="item.id">
                  <td>#{{ item.id }}</td>
                  <td class="d-flex align-items-center gap-2">
                      <img class="img-40 rounded-circle" :src="item.profile ? item.profile : 'https://fakeimg.pl/40x40/?text=40x40'" alt="profile">
                      <div>
                        <p class="mb-0 text-dark">{{ item.first_name }} {{ item.last_name }}</p>
                        <p class="mb-0">{{ item.email }}</p>
                      </div>
                    </td>
                  <td>{{ item.role }}</td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <label :class="['form-check-label', 'mb-0', { 'txt-primary': item.status }]">{{ item.status ? 'Enabled' : 'Disabled' }}</label>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="item.status" :true-value="1" :false-value="0" 
                        @click.prevent="confirmSwitch(item)">
                      </div>
                    </div>
                  </td>
                  <td>{{ item.created_at }}</td>
                   <td>
                    <router-link 
                      :to="{ 
                        name: 'administratorDetail', 
                        params: { administratorId: item.id },
                        query: { 
                          status: selected.status,
                          role: selected.role.id,
                          search: listRequest.search,
                          page: listRequest.page,
                        }
                      }" 
                      class="me-3">
                       <i class="fa fa-file-text-o fs-5" aria-hidden="true"></i>
                    </router-link>
                    <a href="javascript:;" @click.prevent="confirmDelete(item.id)">
                      <i class="fa fa-trash-o fs-4" aria-hidden="true"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Pagination :total="total" :currentPage.sync="listRequest.page" :limit="listRequest.limit" @updatePage="updatePage" />
      </div>
    </div>
  </div>
  <addNew v-if="shown.addNew" ref="addNew" @hide="hideModal('addNew')" @update="getList" />
  <deleteModal v-if="shown.deleteModal" ref="deleteModal" :type="'administrator'"  @update="handleDelete" @hide="hideModal('deleteModal')" />
  <publishModal v-if="shown.publishModal" ref="publishModal"  @update="handlePublish" @hide="hideModal('publishModal')" />
  <SwitchCheck v-if="shown.switchCheck" ref="switchCheck" :text="text" @handleSwitch="switchStatus" @hide="hideModal('switchCheck')" />
</template>

<script>
import Modal from "bootstrap/js/dist/modal";
import addNew from '@/components-gc/modal/setting/administrator/addNew.vue';
import deleteModal from '@/components-gc/modal/delete.vue';
import SwitchCheck from '@/components-gc/modal/switchCheck.vue';
import Pagination from '@/components-gc/Pagination.vue';
import { checkStatusCode } from '@/methods-gc/statusCode';

export default {
  data() {
    return {
      list: [],
      listRequest: {
        role_id: '',
        enabled: '',
        page: 1,
        limit: 10,
        order_by: "",
        order: "",
        search: '',
      },
      total: 0,
      options: {
        status: ['All', 'Enabled', 'Disabled'],
        roles: []
      },
      selected: {
        status: '',
        role: ''
      },
      switchItem: null,
      modals: {},
      shown: {
        addNew: false,
        deleteModal: false,
        switchCheck: false
      },
      previousListRequest: {},
      administratorId: null
    };
  },
  components: {
    addNew,
    deleteModal,
    Pagination,
    SwitchCheck
  },
  async mounted() {
    if (this.$route.query) {
      this.selected.status = this.$route.query.status || '';
      this.selectChange('status', this.selected.status);
      this.listRequest.search = this.$route.query.search || '';
      this.listRequest.page = parseInt(this.$route.query.page) || 1;
    }
    await this.getRole();
    await this.getList();
  },
  methods: {
    async getRole() {
      const api = `${process.env.VUE_APP_PATH}/global/roles`;
      try {
        const res = await this.axios.get(api);
        if (res.data.status_code === 'SYSTEM_1000') {
          this.options.roles = res.data.roles;
          this.options.roles.unshift({ id: '', name: 'All' });
          
          if (this.$route.query.role) {
            this.selected.role = this.options.roles.find(role => role.id === parseInt(this.$route.query.role)) || '';
            this.selectChange('role', this.selected.role);
          }
        } else {
          console.log('Error:', res.data.status_code);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    },
    getList() {
      const params = { ...this.listRequest };
      Object.keys(params).forEach(key => {
        if (params[key] === 'All') {
          params[key] = '';
        }
      });

      const api = `${process.env.VUE_APP_PATH}/administrator/list`;
      this.axios.post(api, params)
        .then((res) => {
          if (res.data.status_code === 'SYSTEM_1000') {
            this.list = res.data.data;
            this.total = res.data.total;
          } else {
            const msg = checkStatusCode(res.data.status_code);
            this.$toastError(msg);
          }
        }).catch(error => {
          console.log('Error:', error);
        });
    },
    sortList(column) {
      if (this.listRequest.order_by === column) {
        this.listRequest.order = this.listRequest.order === 'asc' ? 'desc' : 'asc';
      } else {
        this.listRequest.order_by = column;
        this.listRequest.order = 'asc';
      }
      this.getList();
    },
    isActiveSort(column) {
      return this.listRequest.order_by === column;
    },
    updatePage(page) {
      this.listRequest.page = page;
      this.getList();
    },
    showModal(name) {
      this.shown[name] = true;
      this.$nextTick(() => {
        this.modals[name] = new Modal(this.$refs[name].$el);
        this.modals[name].show();
        this.$refs[name].$el.addEventListener('hidden.bs.modal', () => this.onHidden(name));
      });
    },
    hideModal(name) {
      this.modals[name].hide();
    },
    onHidden(name) {
      this.shown[name] = false;
      this.$refs[name].$el.removeEventListener('hidden.bs.modal', () => this.onHidden(name));
    },
    selectChange(type, val) {
      if (val && val !== 'All') {
        switch (type) {
          case 'role':
            this.listRequest.role_id = val.id;
            break;
          case 'status':
            this.listRequest.enabled = val === 'Enabled' ? 'Y' : val === 'Disabled' ? 'N' : '';
            break;
        }
      } else {
        this.listRequest[type === 'role' ? 'role_id' : 'enabled'] = '';
      }
    },
    confirmSwitch(item) {
      this.switchItem = item;
      this.showModal('switchCheck');
    },
    switchStatus() {
      this.hideModal('switchCheck');
      const api = `${process.env.VUE_APP_PATH}/administrator/switch`;
      this.axios.post(api, {
        admin_id: this.switchItem.id,
        enable: !this.switchItem.status
      })
      .then((res) => {
        if (res.data.status_code !== 'SYSTEM_1000') {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        } else {
          this.getList();
          this.$toastSuccess('Status updated successfully');
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
    clearAll() {
      const isAllCleared = !this.listRequest.enabled && !this.listRequest.role_id;
      if (!isAllCleared) {
        this.listRequest = { ...this.listRequest, enabled: '', role_id: '' };
        this.selected.status = '';
        this.selected.role = '';
        this.listRequest.page = 1;
        this.getList();
      }
    },
    handleSearch() {
      if (JSON.stringify(this.listRequest) !== JSON.stringify(this.previousListRequest)) {
        this.previousListRequest = { ...this.listRequest };
        this.listRequest.page = 1;
        this.getList();
      }
    },
    confirmDelete(id) {
      this.administratorId = id;
      this.showModal('deleteModal');
    },
    handleDelete() {
      this.hideModal('deleteModal');

      const api = `${process.env.VUE_APP_PATH}/administrator/delete`;
      const payload = {
        admin_id: this.administratorId
      };

      this.axios.post(api, payload)
      .then((res) => {
        if (res.data.status_code === 'SYSTEM_1000') {
          this.$toastSuccess('Deleted successfully');
          this.hideModal('deleteModal');
          this.listRequest.page = 1;
          this.getList();
        } else {
          const msg = checkStatusCode(res.data.status_code);
          this.$toastError(msg);
        }
      }).catch(error => {
        console.log('Error:', error);
      });
    },
  },
   watch: {
     'listRequest.search'(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.listRequest.page = 1;
        this.getList();
      }
    }
  },
};
</script>

<style scoped>
  /* .select-wrap.role-wrap {
    width: 180px;
  } */
</style>